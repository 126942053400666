import { IVersionsData, VERSIONS_DATA } from '@/data/versions.data';
import { SetStateAction } from 'react';

interface IVersionsContext {
  get_selected_version_index: number;
  set_selected_version_index: React.Dispatch<SetStateAction<number>>;
  get_selected_version_name: string;
  set_selected_version_name: React.Dispatch<SetStateAction<string>>;
  get_selected_color: string;
  set_selected_color: React.Dispatch<SetStateAction<string>>;
  get_selected_color_name: string;
  set_selected_color_name: React.Dispatch<SetStateAction<string>>;
  get_version_src: string;
  set_version_src: React.Dispatch<SetStateAction<string>>;
  get_version: IVersionsData;
  set_version: React.Dispatch<SetStateAction<IVersionsData>>;
}

export interface IVersionsContextType {
  VERSION_CONTEXT: IVersionsContext;
}

export const VERSION_INITIAL_VALUE: IVersionsContextType = {
  VERSION_CONTEXT: {
    get_selected_version_index: 0,
    set_selected_version_index: () => null,
    get_selected_version_name: '',
    set_selected_version_name: () => null,
    get_selected_color: '',
    set_selected_color: () => null,
    get_selected_color_name: '',
    set_selected_color_name: () => null,
    get_version_src: '',
    set_version_src: () => null,
    get_version: VERSIONS_DATA[0],
    set_version: () => null,
  },
};
