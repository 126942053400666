import React, { FC, useEffect, useRef } from "react";

interface VimeoEmbedProps {
  title: string;
  id?: string;
  className?: string;
  youtubeVideoId?: string;
  isActive?: boolean;
}

const VimeoEmbed: FC<VimeoEmbedProps> = ({
  title,
  id,
  className = "",
  youtubeVideoId,
  isActive = false,
}) => {
  const videoRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!isActive && videoRef.current) {
      const src = videoRef.current.src;
      videoRef.current.src = src;
    }
  }, [isActive]);

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.contentWindow?.postMessage(
        JSON.stringify({ event: "command", func: "pauseVideo", args: "" }),
        "*"
      );
    }
  };

  return (
    <>
      <iframe
        ref={videoRef}
        src={`https://www.youtube.com/embed/${youtubeVideoId}?rel=0&loop=0&autoplay=0&playlist=${youtubeVideoId}`}
        title={title}
        className={className}
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop"
        loading="lazy"
      />
    </>
  );
};

export default VimeoEmbed;
