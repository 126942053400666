import { FC } from "react";

const MenuScorpion: FC<{}> = ({
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20.633"
      height="22.889"
      viewBox="0 0 20.633 22.889"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4901"
            data-name="Rectangle 4901"
            width="20.633"
            height="22.889"
            fill="#fefefe"
          />
        </clipPath>
      </defs>
      <g id="logo-abarth-scorpion" clipPath="url(#clip-path)">
        <path
          id="Path_4994"
          data-name="Path 4994"
          d="M124.552.973c1.024-.141,1.958-.272,2.892-.4C128.886.386,130.33.2,131.771.005a.358.358,0,0,1,.365.146q2.3,2.629,4.616,5.251c.118.134.2.2-.041.317-2.39,1.166-4.774,2.345-7.159,3.521-.038.019-.078.032-.133.055-.189-.7-.362-1.382-.568-2.058-.078-.256.11-.226.221-.258q2.366-.681,4.736-1.349c.652-.185.652-.181.349-.79-.251-.5-.5-1.009-.759-1.534-.535.481-1.047.939-1.557,1.4a.378.378,0,0,1-.317.139c-1.468-.16-2.938-.313-4.406-.468A.717.717,0,0,1,127,4.332l2.841-1.214L124.552.973"
          transform="translate(-116.231 0)"
          fill="#fefefe"
        />
        <path
          id="Path_4995"
          data-name="Path 4995"
          d="M8.95,47.764l-7.5,3.7c-.279-1.382-.551-2.736-.824-4.09-.2-1-.4-2-.6-3a.389.389,0,0,1,.1-.414Q2.605,41.5,5.078,39.021a.206.206,0,0,1,.161-.059l-1.47,5.364L6.205,42.9c.073.167-.032.244-.085.331-.679,1.117-1.367,2.229-2.038,3.351a.643.643,0,0,1-.489.333c-.6.1-1.2.224-1.84.345.341.687.67,1.355,1,2.02.1.2.188.015.252-.032.753-.549,1.5-1.107,2.25-1.662q1.043-.774,2.085-1.55c.117-.088.192-.146.312.019.411.564.843,1.114,1.295,1.707"
          transform="translate(0 -36.365)"
          fill="#fefefe"
        />
        <path
          id="Path_4996"
          data-name="Path 4996"
          d="M115.673,138.887c-.049.3-.093.6-.147.9-.029.159-.177.111-.273.12-.608.058-1.217.114-1.827.151-.238.014-.314.044-.254.325.077.362.235.455.585.414.584-.068,1.173-.108,1.761-.129.238-.008.286-.218.465-.324a4.829,4.829,0,0,1-.075.911c-.007.161-.157.124-.26.132-.609.051-1.217.11-1.827.142-.261.014-.282.09-.233.335.072.358.228.461.587.409a12.694,12.694,0,0,1,1.363-.091.95.95,0,0,0,.8-.341c-.021.282-.045.564-.062.846-.011.19-.154.153-.268.164l-2.057.2c.254.967.048.738.932.721.322-.006.644-.035.964-.066.278-.027.587.011.771-.409a3.435,3.435,0,0,1-.042.972c-.015.137-.182.1-.283.108-.553.057-1.106.106-1.659.157-.149.014-.317.008-.19.241.075.137-.032.2-.123.265a5.524,5.524,0,0,1-.842.594,1.952,1.952,0,0,1-1.419.168c-.146-.045-.175.063-.231.12-.4.415-.8.833-1.185,1.262-.118.131-.206.159-.355.05-.2-.143-.408-.262-.633-.43.484.052.658-.329.906-.6.263-.286.5-.593.778-.868.178-.178.164-.3.01-.487-.3-.366-.293-.376-.612-.034-.371.4-.746.792-1.111,1.2-.116.128-.207.163-.357.047-.2-.153-.418-.281-.636-.457.42.069.6-.224.819-.471.306-.352.607-.708.927-1.047.161-.171.195-.291.027-.49-.286-.337-.271-.351-.559-.036-.382.418-.77.83-1.139,1.258a.259.259,0,0,1-.412.054c-.2-.139-.415-.252-.643-.414.558.039.728-.428,1.018-.729s.536-.623.822-.917c.147-.151.138-.252.014-.409-.3-.382-.294-.387-.612-.039-.388.427-.782.849-1.166,1.279-.1.114-.186.159-.333.067-.23-.144-.474-.264-.713-.429.366.068.534-.163.7-.4.056-.083.125-.157.175-.243a4.865,4.865,0,0,1,2.5-2.12,24.617,24.617,0,0,0,2.327-1.143,1.884,1.884,0,0,1,1.015-.235c.521.029,1.044.017,1.564.054a.784.784,0,0,0,.713-.3"
          transform="translate(-99.554 -129.631)"
          fill="#fefefe"
        />
        <path
          id="Path_4997"
          data-name="Path 4997"
          d="M132.5,112.192c.035.032.077.067.115.107.513.538,1.02,1.083,1.541,1.612.143.145.112.193-.051.272q-1.793.872-3.58,1.757c-.129.064-.231.138-.264-.1-.1-.735-.219-1.468-.342-2.2-.03-.18.013-.232.2-.229.344.007.689-.025,1.033-.013a.528.528,0,0,0,.5-.236c.207-.288.44-.557.666-.831.046-.057.076-.14.182-.141"
          transform="translate(-121.236 -104.715)"
          fill="#fefefe"
        />
        <path
          id="Path_4998"
          data-name="Path 4998"
          d="M191.262,233.189c0,1.08,0,2.1,0,3.118,0,.155-.022.235-.206.249-.453.034-.9.106-1.356.14a.858.858,0,0,1-.789-.742c0-.521.014-1.042-.006-1.562-.009-.238.083-.324.3-.313a2.321,2.321,0,0,0,1.69-.634,4.4,4.4,0,0,1,.366-.255"
          transform="translate(-176.287 -217.648)"
          fill="#fefefe"
        />
        <path
          id="Path_4999"
          data-name="Path 4999"
          d="M171.387,283c-.857.978-1.653,1.884-2.446,2.794-.1.11-.166.108-.272.012-.246-.224-.509-.429-.754-.654a.794.794,0,0,1-.084-1.077q.5-.619,1.011-1.236c.067-.082.132-.2.257-.077.493.494,1.094.358,1.683.293.174-.019.349-.032.6-.056"
          transform="translate(-156.459 -263.863)"
          fill="#fefefe"
        />
        <path
          id="Path_5000"
          data-name="Path 5000"
          d="M61.114,235.975l1.4-5.1.061.008c.19,1.362.377,2.724.575,4.085.022.149-.038.195-.152.241-.614.248-1.226.5-1.886.768"
          transform="translate(-57.031 -215.488)"
          fill="#fefefe"
        />
        <path
          id="Path_5001"
          data-name="Path 5001"
          d="M135.176,311.127c-.952.209-1.879.414-2.807.616-.081.018-.2.063-.236-.048-.128-.395-.415-.775-.114-1.217.063-.092.1-.189.214-.233q.823-.313,1.643-.635c.088-.034.183-.085.2.054.071.649.61.942,1.011,1.341a1.155,1.155,0,0,1,.091.123"
          transform="translate(-123.075 -288.938)"
          fill="#fefefe"
        />
        <path
          id="Path_5002"
          data-name="Path 5002"
          d="M82.839,299.224c.255-.1.47-.183.68-.275.112-.049.187.006.273.057.714.424,1.43.845,2.142,1.271.083.05.247.084.143.235a1.191,1.191,0,0,0,.016,1.16.25.25,0,0,1,.01.224l-3.264-2.673"
          transform="translate(-77.305 -279.007)"
          fill="#fefefe"
        />
      </g>
    </svg>
  );
};

export default MenuScorpion;
