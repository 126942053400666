import { includeResponsive } from "@/utils/imagePrefixes";

const PREFIX = `${process.env.BASE_PREFIX}images/`;
const SECTION_PREFIX = "ProductDetails/";

export const defaultGreen = [
  includeResponsive(`${SECTION_PREFIX}/defaultgreen/defaultGreen@3x`, "jpg", {
    prefix: PREFIX,
  }),
];

const DESIGN_PREFIX = `${SECTION_PREFIX}design/`;
export const design = [
  includeResponsive(
    `${DESIGN_PREFIX}fiat-fastback-design-design-suv-coupe@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${DESIGN_PREFIX}fiat-fastback-design-rodas-aro-18@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${DESIGN_PREFIX}fiat-fastback-design-lanterna-full-led@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${DESIGN_PREFIX}fiat-fastback-design-farois-lanternas-full-led@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
];

const PERFORMANCE_PREFIX = `${SECTION_PREFIX}performance/`;
export const performance = [
  includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-fastback-performance-powered-by-abarth@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-fastback-performance-botao-sport@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-fastback-performance-motor-turbo-flex@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${PERFORMANCE_PREFIX}fiat-fastback-performance-traction-control@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
];

const INTERIOR_PREFIX = `${SECTION_PREFIX}espaco-interior/`;
export const interior = [
  includeResponsive(
    `${INTERIOR_PREFIX}fiat-fastback-espaco-interior-porta-malas@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${INTERIOR_PREFIX}fiat-fastback-espaco-interior-console-central-elevado@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${INTERIOR_PREFIX}fiat-fastback-espaco-interior-bancos-em-couro@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${INTERIOR_PREFIX}fiat-fastback-espaco-interior-volante-multifuncional@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${INTERIOR_PREFIX}fiat-fastback-espaco-interior-paddle-shifters@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
];

const SEGURANCA_PREFIX = `${SECTION_PREFIX}seguranca/`;
export const seguranca = [
  includeResponsive(`${SECTION_PREFIX}/defaultgreen/defaultGreen@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${SECTION_PREFIX}/defaultgreen/defaultGreen@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${SECTION_PREFIX}/defaultgreen/defaultGreen@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(
    `${SEGURANCA_PREFIX}fiat-fastback-seguranca-seguranca-monitoramento-pressão-pneus@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${SEGURANCA_PREFIX}fiat-fastback-seguranca-airbags@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${SEGURANCA_PREFIX}fiat-fastback-seguranca-fixacao-isofix@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
];

const TECNOLOGIA_PREFIX = `${SECTION_PREFIX}tecnologia-conectividade/`;
export const tecnologia = [
  includeResponsive(`${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-conectividade-central-multimidia@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-cluster-full-digital@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-carregamento-celular-sem-fio@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-partida-chave-remota@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-ar-condicionado-automatico-digital@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-ar-condicionado-automatico-digital@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
  includeResponsive(
    `${TECNOLOGIA_PREFIX}fiat-fastback-tecnologia-conectividade-farois-limpadores-automaticos@3x`,
    "jpg",
    {
      prefix: PREFIX,
    }
  ),
];

const ACESSORIOS_PREFIX = `${SECTION_PREFIX}acessorios/`;
export const acessorios = [
  includeResponsive(`${ACESSORIOS_PREFIX}fiat-fastback-acessorios-rodas-liga-leve-17@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${ACESSORIOS_PREFIX}fiat-fastback-acessorios-tapete-porta-malas@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${ACESSORIOS_PREFIX}fiat-fastback-acessorios-tapete-boracha-carpete@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${ACESSORIOS_PREFIX}fiat-fastback-acessorios-protetor-soleira-vinil@3x`, "jpg", {
    prefix: PREFIX,
  }),
  includeResponsive(`${ACESSORIOS_PREFIX}fiat-fastback-acessorios-alarme-volumetrico@3x`, "jpg", {
    prefix: PREFIX,
  }),
];

const SERVICOS_PREFIX = `${SECTION_PREFIX}servicos/`;
export const servicos = [
  includeResponsive(`${SERVICOS_PREFIX}fiat-fastback-servicos-revisao-sob-medida@3x`, "jpg", {
    prefix: PREFIX,
  }),
];
