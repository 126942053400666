import {
  design,
  interior,
  tecnologia,
  performance,
  seguranca,
  acessorios,
  servicos,
} from "@/assets/productDetails";
import { NewCta } from "@/components/new-cta";
import { PRODUCT_DETAILS_REF } from "@/data/menu.data";
import { MenuLabels } from "@/models";
import DataLayer from "@/utils/DataLayer";
import formatString from "@/utils/formatString";

export interface ProductTabSlide {
  id: string;
  index?: number;
  title: string;
  titleToFront: JSX.Element;
  altImage: string;
  titleImage: string;
  slug: string;
  linkBtn?: string;
  labelBtn?: string;
  titleBtn?: string;
  altBtn?: string;
  titleFirst?: string;
  titleSecond?: string;
  highLightedFirst: boolean;
  breakTitle?: boolean;
  descriptionDesktop: JSX.Element;
  descriptionMobile: JSX.Element;
  parentSlug?: string;
  image: string;
  vimeoId?: {
    mobile: string;
    desktop: string;
  };
  tabId: string;
  fileUrl?: string;
  videoId?: string;
  firstChildrenTab?: boolean;
}

export interface ProductTab {
  id: string;
  title: string;
  slug: string;
  parentSlug?: string;
  children: Omit<ProductTabSlide, "tabId">[];
}

const PREFIX = `${process.env.BASE_PREFIX}images/ProductDetails/`;
const QUERO_LINK = "/";
const QUERO_LABEL = "Eu quero";

const detailTitle = [
  { title: "design", titleToFront: "Design" },
  { title: "performance", titleToFront: "Performance" },
  { title: "interior", titleToFront: "Espaço e Interior" },
  { title: "tecnologia", titleToFront: "Tecnologia e Conectividade" },
  { title: "acessorios", titleToFront: "Acessórios" },
  { title: "seguranca", titleToFront: "Segurança" },
  { title: "servicos", titleToFront: "Serviços" },
];
export const productDetails: ProductTab[] = [
  // Design
  {
    id: detailTitle[0].title,
    title: detailTitle[0].titleToFront,
    slug: detailTitle[0].title,
    children: [
      {
        id: formatString("SUV COUPÉ"),
        slug: formatString("SUV COUPÉ"),
        title: "SUV COUPÉ",
        titleToFront: <>SUV <br/> COUPÉ</>,
        highLightedFirst: true,
        parentSlug: detailTitle[0].title,
        altImage: "SUV COUPÉ Fastback",
        titleImage: "SUV COUPÉ",
        descriptionDesktop: (
          <p>
            O autêntico design italiano,
            <br />
            mais premium e arrebatador.
          </p>
        ),
        descriptionMobile: (
          <p>
            O autêntico design italiano,
            <br />
            mais premium e arrebatador.
          </p>
        ),
        image: design[0].fullPath,
      },
      {
        id: formatString("RODAS ARO 18”"),
        slug: formatString("RODAS ARO 18”"),
        title: "RODAS ARO 18”",
        titleToFront: (
          <>
            RODAS ARO
            <br />
            18”
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[0].title,
        altImage:
          "Traseira do Fiat Fastback na cor vermelha, com destaque para escapamento duplo",
        titleImage: "RODAS ARO 18”",
        descriptionDesktop: (
          <p>
            As grandes rodas aro 18” trazem
            <br />
            robustez e muito estilo.
          </p>
        ),
        descriptionMobile: (
          <p>
            As grandes rodas aro 18” trazem
            <br />
            robustez e muito estilo.
          </p>
        ),
        image: design[1].fullPath,
      },
      {
        id: formatString("FARóis e lanternas LED"),
        slug: formatString("FARóis e lanternas LED"),
        title: "FARóis e lanternas LED",
        titleToFront: (
          <>
            FARóis
            <br />e lanternas LED
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[0].title,
        altImage: "Volante esportivo com detalhe de escorpião no centro.",
        titleImage: "FARóis e lanternas LED",
        descriptionDesktop: (
          <p>
            Tecnologia dos faróis totalmente em LED garante
            <br />
            melhor luminosidade, maior durabilidade e mais
            <br />
            economia para você.
          </p>
        ),
        descriptionMobile: (
          <p>
            Tecnologia dos faróis totalmente em LED garante
            <br />
            melhor luminosidade, maior durabilidade e mais
            <br />
            economia para você.
          </p>
        ),
        image: design[2].fullPath,
      },
      {
        id: formatString("LANTERNAs LED"),
        slug: formatString("LANTERNAs LED"),
        title: "LANTERNAs LED",
        titleToFront: <>LANTERNAs <br/> LED</>,
        highLightedFirst: true,
        parentSlug: detailTitle[0].title,
        altImage:
          "Volante esportivo com detalhe das borboletas na parte traseira, para troca de marcha.",
        titleImage: "LANTERNAs LED",
        descriptionDesktop: (
          <p>
            A beleza e modernidade do LED
            <br />
            enaltecem ainda mais a bela traseira
            <br />
            Coupé do Fastback.
          </p>
        ),
        descriptionMobile: (
          <p>
            A beleza e modernidade do LED
            <br />
            enaltecem ainda mais a bela traseira
            <br />
            Coupé do Fastback.
          </p>
        ),
        image: design[3].fullPath,
      },
    ],
  },

  // Performance
  {
    id: detailTitle[1].title,
    title: detailTitle[1].titleToFront,
    slug: detailTitle[1].title,
    children: [
      {
        id: formatString("Powered by Abarth"),
        slug: formatString("Powered by Abarth"),
        title: "Powered by Abarth",
        titleToFront: (
          <>
            Powered
            <br />
            by Abarth
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[1].title,
        altImage:
          "Central multimídia de 10,1” do Fastback Abarth na tela de navegação.",
        titleImage: "Powered by Abarth",
        descriptionDesktop: (
          <p>
            A Abarth traz das pistas o motor de performance
            <br />
            TURBO 270 FLEX para você dirigir nas ruas e<br />
            estradas: potência de até 185CV com um torque de
            <br />
            270NM.
          </p>
        ),
        descriptionMobile: (
          <p>
            A Abarth traz das pistas o motor de performance
            <br />
            TURBO 270 FLEX para você dirigir nas ruas e<br />
            estradas: potência de até 185CV com um torque de
            <br />
            270NM.
          </p>
        ),
        image: performance[0].fullPath,
      },
      {
        id: formatString("MODO SPORT"),
        slug: formatString("MODO SPORT"),
        title: "MODO SPORT",
        titleToFront: (
          <>
            MODO
            <br />
            SPORT
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[1].title,
        altImage:
          "Painel digital de 7” do Fastback Abarth, que mostra informações como pressão do turbo e força G com o modo poison ativado.",
        titleImage: "MODO SPORT",
        descriptionDesktop: (
          <p>
            Selecione o modo de direção Sport e veja
            <br />
            o painel de instrumentos full digital e todo
            <br />
            o carro entrarem em uma configuração de direção
            <br />
            envenenada com respostas ainda mais rápidas ao
            <br />
            volante.
          </p>
        ),
        descriptionMobile: (
          <p>
            Selecione o modo de direção Sport e veja
            <br />
            o painel de instrumentos full digital e todo
            <br />
            o carro entrarem em uma configuração de direção
            <br />
            envenenada com respostas ainda mais rápidas ao
            <br />
            volante.
          </p>
        ),
        image: performance[1].fullPath,
      },
      {
        id: formatString("MOTOR TURBO 200 FLEX"),
        slug: formatString("MOTOR TURBO 200 FLEX"),
        title: "MOTOR TURBO 200 FLEX",
        titleToFront: (
          <>
            MOTOR TURBO
            <br />
            200 FLEX
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[1].title,
        altImage:
          "Área de carregamento sem fio no console central, com smartphone recarregando por indução",
        titleImage: "MOTOR TURBO 200 FLEX",
        descriptionDesktop: (
          <p>
            Entrega a combinação perfeita entre performance e<br />
            um consumo de combustível incrivelmente
            <br />
            econômico.
          </p>
        ),
        descriptionMobile: (
          <p>
            Entrega a combinação perfeita entre performance e<br />
            um consumo de combustível incrivelmente
            <br />
            econômico.
          </p>
        ),
        image: performance[2].fullPath,
      },
      {
        id: formatString("(TC+) Traction Control plus"),
        slug: formatString("(TC+) Traction Control plus"),
        title: "(TC+) Traction Control plus",
        titleToFront: <>(TC+) Traction <br/> Control plus</>,
        highLightedFirst: true,
        parentSlug: detailTitle[1].title,
        altImage:
          "Detalhe atrás do volante do Fastback Abarth, com destaque para botão Start/Stop, que necessita somente da chave no interior do carro para dar partida.",
        titleImage: "(TC+) Traction Control plus",
        descriptionDesktop: (
          <p>
            O Traction Control Plus é um sistema de controle de
            <br />
            tração avançado que transfere o torque do seu SUV
            <br />
            para a roda com maior aderência para ajudá-lo a<br />
            vencer qualquer obstáculo! Para acioná-lo, basta
            <br />
            apertar a tecla TC+.
          </p>
        ),
        descriptionMobile: (
          <p>
            O Traction Control Plus é um sistema de controle de
            <br />
            tração avançado que transfere o torque do seu SUV
            <br />
            para a roda com maior aderência para ajudá-lo a<br />
            vencer qualquer obstáculo! Para acioná-lo, basta
            <br />
            apertar a tecla TC+.
          </p>
        ),
        image: performance[3].fullPath,
      },
    ],
  },

  // Espaço e Interior
  {
    id: detailTitle[2].title,
    title: detailTitle[2].titleToFront,
    slug: detailTitle[2].title,
    children: [
      {
        id: formatString("PORTA MALAS DE 600L"),
        slug: formatString("PORTA MALAS DE 600L"),
        title: "PORTA MALAS DE 600L",
        titleToFront: (
          <>
            PORTA MALAS
            <br />
            DE 600L
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[2].title,
        altImage:
          "Detalhe do acabamento texturizado no interior do Fastback Abarth, cm destaque para emblema Abarth acima do porta-luvas.",
        titleImage: "PORTA MALAS DE 600L",
        descriptionDesktop: (
          <p>
            Aproveite um espaço infinito para acomodar tudo
            <br />
            que você quiser! Acomode todo tipo de bagagem:
            <br />
            desde malas de todos os tamanhos, equipamentos
            <br />
            esportivos e até mesmo sua prancha.
          </p>
        ),
        descriptionMobile: (
          <p>
            Aproveite um espaço infinito para acomodar tudo
            <br />
            que você quiser! Acomode todo tipo de bagagem:
            <br />
            desde malas de todos os tamanhos, equipamentos
            <br />
            esportivos e até mesmo sua prancha.
          </p>
        ),
        image: interior[0].fullPath,
      },
      {
        id: formatString("CONSOLE CENTRAL ELEVADO"),
        slug: formatString("CONSOLE CENTRAL ELEVADO"),
        title: "CONSOLE CENTRAL ELEVADO",
        titleToFront: (
          <>
            CONSOLE
            <br />
            CENTRAL ELEVADO
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[2].title,
        altImage:
          "Detalhe do emblema do escorpião em baixo relevo no banco de couro do Fastback Abarth.",
        titleImage: "CONSOLE CENTRAL ELEVADO",
        descriptionDesktop: (
          <p>
            O conforto do apoio de braço, unido ao espaço de
            <br />
            armazenamento de porta objetos e um belo design
            <br />
            que enriquece o interior do Fastback.
          </p>
        ),
        descriptionMobile: (
          <p>
            O conforto do apoio de braço, unido ao espaço de
            <br />
            armazenamento de porta objetos e um belo design
            <br />
            que enriquece o interior do Fastback.
          </p>
        ),
        image: interior[1].fullPath,
      },
      {
        id: formatString("BANCOS EM COURO"),
        slug: formatString("BANCOS EM COURO"),
        title: "BANCOS EM COURO",
        titleToFront: (
          <>
            BANCOS
            <br />
            EM COURO
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[2].title,
        altImage:
          "Volante, painel de controle e central multimídia sob ponto de vista dos bancos traseiros.",
        titleImage: "BANCOS EM COURO",
        descriptionDesktop: (
          <p>
            Com abas laterais e costuras aparentes que trazem
            <br />
            ainda mais conforto e esportividade ao interior do
            <br />
            Fastback.
          </p>
        ),
        descriptionMobile: (
          <p>
            Com abas laterais e costuras aparentes que trazem
            <br />
            ainda mais conforto e esportividade ao interior do
            <br />
            Fastback.
          </p>
        ),
        image: interior[2].fullPath,
      },
      {
        id: formatString("VOLANTE MULTIFUNCIONAL"),
        slug: formatString("VOLANTE MULTIFUNCIONAL"),
        title: "VOLANTE MULTIFUNCIONAL",
        titleToFront: (
          <>
            VOLANTE
            <br />
            MULTIFUNCIONAL
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[2].title,
        altImage:
          "Volante, painel de controle e central multimídia sob ponto de vista dos bancos traseiros.",
        titleImage: "VOLANTE MULTIFUNCIONAL",
        descriptionDesktop: (
          <p>
            Todos os comandos ao alcance dos dedos e base
            <br />
            achatada esportiva que garante mais espaço para as
            <br />
            pernas.
          </p>
        ),
        descriptionMobile: (
          <p>
            Todos os comandos ao alcance dos dedos e base
            <br />
            achatada esportiva que garante mais espaço para as
            <br />
            pernas.
          </p>
        ),
        image: interior[3].fullPath,
      },
      {
        id: formatString("PADDLE SHIFTERS"),
        slug: formatString("PADDLE SHIFTERS"),
        title: "PADDLE SHIFTERS",
        titleToFront: (
          <>
            PADDLE
            <br />
            SHIFTERS
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[2].title,
        altImage:
          "Volante, painel de controle e central multimídia sob ponto de vista dos bancos traseiros.",
        titleImage: "PADDLE SHIFTERS",
        descriptionDesktop: (
          <p>
            Para quem gosta de uma direção mais esportiva:
            <br />
            com esse câmbio localizado ao alcance dos dedos
            <br />
            atrás do volante, você faz uma troca de marchas mais
            <br />
            precisa, prática e rápida.
          </p>
        ),
        descriptionMobile: (
          <p>
            Para quem gosta de uma direção mais esportiva:
            <br />
            com esse câmbio localizado ao alcance dos dedos
            <br />
            atrás do volante, você faz uma troca de marchas mais
            <br />
            precisa, prática e rápida.
          </p>
        ),
        image: interior[4].fullPath,
      },
    ],
  },

  // Segurança
  {
    id: detailTitle[5].title,
    title: detailTitle[5].titleToFront,
    slug: detailTitle[5].title,
    children: [
      {
        id: formatString("FRENAGEM AUTÔNOMA DE EMERGÊNCIA"),
        slug: formatString("FRENAGEM AUTÔNOMA DE EMERGÊNCIA"),
        title: "FRENAGEM AUTÔNOMA DE EMERGÊNCIA",
        titleToFront: (
          <>
            FRENAGEM AUTÔNOMA
            <br />
            DE EMERGÊNCIA
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage: "Fastback Abarth vermelho em visão lateral dianteira.",
        titleImage: "FRENAGEM AUTÔNOMA DE EMERGÊNCIA",
        descriptionDesktop: (
          <p>
            O sistema AEB é capaz de reconhecer veículos à sua
            <br />
            frente, através de uma câmera frontal, e acionar
            <br />
            automaticamente os freios, para assim evitar
            <br />
            acidentes.
          </p>
        ),
        descriptionMobile: (
          <p>
            O sistema AEB é capaz de reconhecer veículos à sua
            <br />
            frente, através de uma câmera frontal, e acionar
            <br />
            automaticamente os freios, para assim evitar
            <br />
            acidentes.
          </p>
        ),
        image: seguranca[0].fullPath,
          vimeoId: {
              desktop: '749400213',
              mobile: '749400582',
          },
      },
      {
        id: formatString("ALERTA DE SAÍDA DE FAIXA"),
        slug: formatString("ALERTA DE SAÍDA DE FAIXA"),
        title: "ALERTA DE SAÍDA DE FAIXA",
        titleToFront: (
          <>
            ALERTA DE
            <br />
            SAÍDA DE FAIXA
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Frente do Fastback Abarth, com destaque para o farol de LED, detalhes de design esportivo e grelha com o emblema Abarth.",
        titleImage: "ALERTA DE SAÍDA DE FAIXA",
        descriptionDesktop: (
          <p>
            Caso o carro saia da faixa, o motorista será
            <br />
            prontamente alertado por uma notificação no painel,
            <br />
            alerta sonoro e vibrações no volante.
          </p>
        ),
        descriptionMobile: (
          <p>
            Caso o carro saia da faixa, o motorista será
            <br />
            prontamente alertado por uma notificação no painel,
            <br />
            alerta sonoro e vibrações no volante.
          </p>
        ),
        image: seguranca[1].fullPath,
          vimeoId: {
              desktop: '749400234',
              mobile: '749400609',
          },
      },
      {
        id: formatString("COMUTAÇÃO AUTOMÁTICA DE FAROL ALTO"),
        slug: formatString("COMUTAÇÃO AUTOMÁTICA DE FAROL ALTO"),
        title: "COMUTAÇÃO AUTOMÁTICA DE FAROL ALTO",
        titleToFront: (
          <>
            COMUTAÇÃO
            <br />
            AUTOMÁTICADE FAROL ALTO
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Frente do Fastback Abarth, com destaque para o farol de LED, detalhes de design esportivo e grade com o emblema Abarth.",
        titleImage: "COMUTAÇÃO AUTOMÁTICA DE FAROL ALTO",
        descriptionDesktop: (
          <p>
            Uma câmera frontal que identifica carros vindo no
            <br />
            sentido contrário e regula automaticamente
            <br />a intensidade do seu farol. Assim, você estará sempre
            <br />
            respeitando a visão dos outros motoristas e terá
            <br />
            mais segurança e conforto.
          </p>
        ),
        descriptionMobile: (
          <p>
            Uma câmera frontal que identifica carros vindo no
            <br />
            sentido contrário e regula automaticamente
            <br />a intensidade do seu farol. Assim, você estará sempre
            <br />
            respeitando a visão dos outros motoristas e terá
            <br />
            mais segurança e conforto.
          </p>
        ),
        image: seguranca[2].fullPath,
          vimeoId: {
              desktop: '749400181',
              mobile: '749400552',
          },
      },
      {
        id: formatString("MONITORAMENTO DE PRESSÃO DOS PNEUS"),
        slug: formatString("MONITORAMENTO DE PRESSÃO DOS PNEUS"),
        title: "MONITORAMENTO DE PRESSÃO DOS PNEUS",
        titleToFront: <>MONITORAMENTO DE PRESSÃO DOS PNEUS</>,
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Roda esportiva do Fastback Abarth com detalhes em preto e vermelho.",
        titleImage: "MONITORAMENTO DE PRESSÃO DOS PNEUS",
        descriptionDesktop: (
          <p>
            Sempre que os pneus precisarem ser calibrados o<br />
            painel de instrumentos emitirá um alerta. Praticidade
            <br />e segurança.
          </p>
        ),
        descriptionMobile: (
          <p>
            Sempre que os pneus precisarem ser calibrados o<br />
            painel de instrumentos emitirá um alerta. Praticidade
            <br />e segurança.
          </p>
        ),
        image: seguranca[3].fullPath,
      },
      {
        id: formatString("4 AIRBAGS COM FUNÇÃO DE 6"),
        slug: formatString("4 AIRBAGS COM FUNÇÃO DE 6"),
        title: "4 AIRBAGS COM FUNÇÃO DE 6",
        titleToFront: (
          <>
            4 AIRBAGS
            <br />
            COM FUNÇÃO DE 6
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Roda esportiva do Fastback Abarth com detalhes em preto e vermelho.",
        titleImage: "4 AIRBAGS COM FUNÇÃO DE 6",
        descriptionDesktop: (
          <p>
            O Fastback vem equipado com 2 airbags frontais e 2<br />
            airbags laterais com dupla função: protegem o tórax
            <br />e a cabeça.
          </p>
        ),
        descriptionMobile: (
          <p>
            O Fastback vem equipado com 2 airbags frontais e 2<br />
            airbags laterais com dupla função: protegem o tórax
            <br />e a cabeça.
          </p>
        ),
        image: seguranca[4].fullPath,
      },
        {
            id: formatString("ISOFIX"),
            slug: formatString("SISTEMA ISOFIX"),
            title: "SISTEMA ISOFIX",
            titleToFront: (
                <> Isofix
                </>
            ),
            highLightedFirst: true,
            parentSlug: detailTitle[3].title,
            altImage:
                "Isofix",
            titleImage: "Isofix",
            descriptionDesktop: (
                <p>
                    Tecnologia para o encaixe perfeito e seguro para cadeirinhas de bebês
                    e crianças.
                </p>
            ),
            descriptionMobile: (
                <p>
                    Tecnologia para o encaixe perfeito e seguro para cadeirinhas de bebês
                    e crianças.
                </p>
            ),
            image: seguranca[5].fullPath,
        },
    ],
  },

  // Tecnologia e Conectividade
  {
    id: detailTitle[3].title,
    title: detailTitle[3].titleToFront,
    slug: detailTitle[3].title,
    children: [
      {
        id: formatString("CENTRAL MULTIMÍDIA DE 10,1"),
        slug: formatString("CENTRAL MULTIMÍDIA DE 10,1"),
        title: "CENTRAL MULTIMÍDIA DE 10,1",
        titleToFront: (
          <>
            CENTRAL
            <br />
            MULTIMÍDIA DE 10,1
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage: "CENTRAL MULTIMÍDIA DE 10,1”",
        titleImage: "CENTRAL MULTIMÍDIA DE 10,1",
        descriptionDesktop: (
          <p>
              Uma tela enorme com uma ótima resolução e design flutuante que
              facilita a visualização. Aproveite um sistema de navegação embutido
              com espelhamento de smartphone sem fio extremamente responsivo.
          </p>
        ),
        descriptionMobile: (
          <p>
              Uma tela enorme com uma ótima resolução e design flutuante que
              facilita a visualização. Aproveite um sistema de navegação embutido
              com espelhamento de smartphone sem fio extremamente responsivo.
          </p>
        ),
        image: tecnologia[0].fullPath,
      },
      {
        id: formatString("CLUSTER FULL DIGITAL DE 7”"),
        slug: formatString("CLUSTER FULL DIGITAL DE 7”"),
        title: "CLUSTER FULL DIGITAL DE 7”",
        titleToFront: (
          <>
            CLUSTER FULL
            <br />
            DIGITAL DE 7”
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Frente do Fastback Abarth, com destaque para o farol de LED, detalhes de design esportivo e grelha com o emblema Abarth.",
        titleImage: "CLUSTER FULL DIGITAL DE 7”",
        descriptionDesktop: (
          <p>
              Painel de instrumentos totalmente digital e com configurações
              personalizáveis que tornam sua condução ainda mais agradável.
          </p>
        ),
        descriptionMobile: (
          <p>
              Painel de instrumentos totalmente digital e com configurações
              personalizáveis que tornam sua condução ainda mais agradável.
          </p>
        ),
        image: tecnologia[1].fullPath,
      },
      {
        id: formatString("CARREGAMENTO DO CELULAR SEM FIO"),
        slug: formatString("CARREGAMENTO DO CELULAR SEM FIO"),
        title: "CARREGAMENTO DO CELULAR SEM FIO",
        titleToFront: (
          <>
            CARREGAMENTO
            <br />
            DO CELULAR SEM FIO
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Frente do Fastback Abarth, com destaque para o farol de LED, detalhes de design esportivo e grade com o emblema Abarth.",
        titleImage: "CARREGAMENTO DO CELULAR SEM FIO",
        descriptionDesktop: (
          <p>
              Diga adeus à irritação dos vários cabos embolados e olá para a
              praticidade do carregador sem fio. Basta ter um celular compatível
              para aproveitar essa inovação. E mais: não se preocupe com o
              aquecimento do aparelho, pois há uma saída de ar-condicionado para
              regular sua temperatura.
          </p>
        ),
        descriptionMobile: (
          <p>
              Diga adeus à irritação dos vários cabos embolados e olá para a
              praticidade do carregador sem fio. Basta ter um celular compatível
              para aproveitar essa inovação. E mais: não se preocupe com o
              aquecimento do aparelho, pois há uma saída de ar-condicionado para
              regular sua temperatura.
          </p>
        ),
        image: tecnologia[2].fullPath,
      },
      {
        id: formatString("KEYLESS ENTRY’N GO"),
        slug: formatString("KEYLESS ENTRY’N GO"),
        title: "KEYLESS ENTRY’N GO",
        titleToFront: (
          <>
            KEYLESS
            <br />
            ENTRY’N GO
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Roda esportiva do Fastback Abarth com detalhes em preto e vermelho.",
        titleImage: "KEYLESS ENTRY’N GO",
        descriptionDesktop: (
          <p>
              Apenas carregue a chave no bolso e entre e saia do Fastback com muito
              mais praticidade dando a partida apertando o botão START/STOP.
          </p>
        ),
        descriptionMobile: (
          <p>
              Apenas carregue a chave no bolso e entre e saia do Fastback com muito
              mais praticidade dando a partida apertando o botão START/STOP.
          </p>
        ),
        image: tecnologia[3].fullPath,
      },
      {
        id: formatString("AR-CONDICIONADO AUTOMÁTICO DIGITAL"),
        slug: formatString("AR-CONDICIONADO AUTOMÁTICO DIGITAL"),
        title: "AR-CONDICIONADO AUTOMÁTICO DIGITAL",
        titleToFront: (
          <>
            AR-CONDICIONADO
            <br />
            AUTOMÁTICO DIGITAL
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Roda esportiva do Fastback Abarth com detalhes em preto e vermelho.",
        titleImage: "AR-CONDICIONADO AUTOMÁTICO DIGITAL",
        descriptionDesktop: (
          <p>
              Mais facilidade para encontrar a temperatura ideal. Além disso, ele pode ser controlado facilmente pela central multimídia.
          </p>
        ),
        descriptionMobile: (
          <p>
              Mais facilidade para encontrar a temperatura ideal. Além disso, ele pode ser controlado facilmente pela central multimídia.
          </p>
        ),
        image: tecnologia[4].fullPath,
      },
      {
        id: formatString("FARÓIS E PARA-BRISAS AUTOMÁTICOS"),
        slug: formatString("FARÓIS E PARA-BRISAS AUTOMÁTICOS"),
        title: "FARÓIS E PARA-BRISAS AUTOMÁTICOS",
        titleToFront: (
          <>
              FARÓIS E <br />PARA-BRISAS AUTOMÁTICOS

          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[3].title,
        altImage:
          "Roda esportiva do Fastback Abarth com detalhes em preto e vermelho.",
        titleImage: "FÁRÓIS E LIMPADORES AUTOMÁTICOS",
        descriptionDesktop: (
          <p>
              Mais conforto no seu dia a dia! Se chover ou escurecer o Fastback
              ligará o limpador de vidros e os faróis automaticamente.
          </p>
        ),
        descriptionMobile: (
          <p>
              Mais conforto no seu dia a dia! Se chover ou escurecer o Fastback
              ligará o limpador de vidros e os faróis automaticamente.
          </p>
        ),
        image: tecnologia[6].fullPath,
      },
    ],
  },

  // Acessórios
  {
    id: detailTitle[4].title,
    title: detailTitle[4].titleToFront,
    slug: detailTitle[4].title,
    children: [
      {
        id: formatString("Rodas de liga leve 17’"),
        slug: formatString("Rodas de liga leve 17’"),
        title: "Rodas de liga leve 17’",
        titleToFront: (
          <>
            Rodas de
            <br />
            liga leve 17’
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[4].title,
        altImage:
          "Vista lateral e dianteira do Fastback Abarth vermelho numa pista com vegetação nas laterais e árvores ao fundo.",
        titleImage: "Rodas de liga leve 17’",
        descriptionDesktop: (
          <p>
            Mais esportividade e elegância para o seu Fiat Fastback. Além da Cor
            Escura exclusiva Mopar, as rodas de liga leve são mais leves e
            resistentes que os modelos tradicionais.
          </p>
        ),
        descriptionMobile: (
          <p>
            Mais esportividade e elegância para o seu Fiat Fastback. Além da Cor
            Escura exclusiva Mopar, as rodas de liga leve são mais leves e
            resistentes que os modelos tradicionais.
          </p>
        ),
        image: acessorios[0].fullPath,
      },
      {
        id: formatString("Tapete de porta malas com borda elevada"),
        slug: formatString("Tapete de porta malas com borda elevada"),
        title: "Tapete de porta malas com borda elevada",
        titleToFront: (
          <>
            Tapete de porta
            <br />
            malas com borda
            <br />
            elevada
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[4].title,
        altImage: "Tapete de porta malas com borda elevada",
        titleImage:
          "Vista traseira de Fastback Abarth vermelho numa pista com vegetação nas laterais e árvores ao fundo.",
        descriptionDesktop: (
          <p>
            Tapete de alta resistência com bordas elevadas,
            <br />
            protege o interior do veículo contra líquidos
            <br />
            derramados, terra ou outros detritos que venham
            <br />
            danificar o revestimento original do porta-malas
            <br />
            mantendo-o sempre novo.
          </p>
        ),
        descriptionMobile: (
          <p>
            Tapete de alta resistência com bordas elevadas,
            <br />
            protege o interior do veículo contra líquidos
            <br />
            derramados, terra ou outros detritos que venham
            <br />
            danificar o revestimento original do porta-malas
            <br />
            mantendo-o sempre novo.
          </p>
        ),
        image: acessorios[1].fullPath,
      },
      {
        id: formatString("tapete de borracha com carpete"),
        slug: formatString("tapete de borracha com carpete"),
        title: "tapete de borracha com carpete",
        titleToFront: (
          <>
            tapete de borracha
            <br />
            com carpete
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[4].title,
        altImage:
          "Dianteira do Fastback Abarth vermelho com destaque para Faróis Full Led com comutação automática.",
        titleImage: "tapete de borracha com carpete",
        descriptionDesktop: (
          <p>
            Protetor para o carpete do seu Fiat Fastback. Mais
            <br />
            espessos e resistentes, também ajudam a compor o<br />
            interior do carro, além de possuírem os mesmos
            <br />
            elementos gráficos do veículo.
          </p>
        ),
        descriptionMobile: (
          <p>
            Protetor para o carpete do seu Fiat Fastback. Mais
            <br />
            espessos e resistentes, também ajudam a compor o<br />
            interior do carro, além de possuírem os mesmos
            <br />
            elementos gráficos do veículo.
          </p>
        ),
        image: acessorios[2].fullPath,
      },
      {
        id: formatString("protetor de soleira em vinil"),
        slug: formatString("protetor de soleira em vinil"),
        title: "protetor de soleira em vinil",
        titleToFront: (
          <>
            protetor de
            <br />
            soleira em vinil
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[4].title,
        altImage: "Vista lateral do Fastback Abarth vermelho em fundo cinza.",
        titleImage: "protetor de soleira em vinil",
        descriptionDesktop: (
          <p>
            O protetor de soleira alia proteção e estilo. É um
            <br />
            acessório que personaliza e protege a pintura do seu
            <br />
            carro no momento de entrada e saída dele.
          </p>
        ),
        descriptionMobile: (
          <p>
            O protetor de soleira alia proteção e estilo. É um
            <br />
            acessório que personaliza e protege a pintura do seu
            <br />
            carro no momento de entrada e saída dele.
          </p>
        ),
        image: acessorios[3].fullPath,
      },
      {
        id: formatString("ALARME VOLUMÉTRICO"),
        slug: formatString("ALARME VOLUMÉTRICO"),
        title: "ALARME VOLUMÉTRICO",
        titleToFront: (
          <>
            ALARME
            <br />
            VOLUMÉTRICO
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[4].title,
        altImage:
          "Botão de acionamento do Freio de Estacionamento, no console central próximo ao câmbio do Fastback Abarth.",
        titleImage: "ALARME VOLUMÉTRICO",
        descriptionDesktop: (
          <p>
            Por ter sensores ultrassônicos, o alarme
            <br />
            volumétrico Mopar libera ondas sonoras que avaliam
            <br />a movimentação dentro do carro. Ele dispara sempre
            <br />
            que detecta uma mudança nesse ambiente, como
            <br />
            uma janela quebrada por exemplo.
          </p>
        ),
        descriptionMobile: (
          <p>
            Por ter sensores ultrassônicos, o alarme
            <br />
            volumétrico Mopar libera ondas sonoras que avaliam
            <br />a movimentação dentro do carro. Ele dispara sempre
            <br />
            que detecta uma mudança nesse ambiente, como
            <br />
            uma janela quebrada por exemplo.
          </p>
        ),
        image: acessorios[4].fullPath,
      },
    ],
  },

  // SERVIÇOS
  {
    id: detailTitle[6].title,
    title: detailTitle[6].titleToFront,
    slug: detailTitle[6].title,
    children: [
      {
        id: formatString("Pacotes De serviços"),
        slug: formatString("Pacotes De serviços"),
        title: "Pacotes De serviços",
        titleToFront: (
          <>
            Pacotes
            <br />
            De serviços
          </>
        ),
        highLightedFirst: true,
        parentSlug: detailTitle[5].title,
        altImage: "Fastback Abarth vermelho em visão lateral dianteira.",
        titleImage: "Pacotes De serviços",
        descriptionDesktop: (
          <p>
            Inclua o pacote de manutenção Mopar na compra do
            <br />
            seu Fiat e tenha valores fixos tabelados nas revisões,
            <br />
            mantenha sua garantia de fábrica, use sempre peças
            <br />
            originais e tenha com selo de qualidade Mopar.
          </p>
        ),
        descriptionMobile: (
          <p>
            Inclua o pacote de manutenção Mopar na compra do seu Fiat e tenha
            valores fixos tabelados nas revisões, mantenha sua garantia de
            fábrica, use sempre peças originais e tenha com selo de qualidade
            Mopar.
          </p>
        ),
        image: servicos[0].fullPath,
      },
    ],
  },
];

export const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
  return pDetails.reduce<MenuLabels[]>((prev, item) => {
    return [
      ...prev,
      {
        numberId: 0,
        id: `data-${item.id}`,
        label: item.title,
        slug: item.slug,
        url: `/${item.slug}`,
        prefixRelation: PRODUCT_DETAILS_REF,
        isDetails: true,
      },
    ];
  }, []);
};

let index = -1;

export const allSlides: ProductTabSlide[] = productDetails.reduce(
  (prev, curr) => {
    return [
      ...prev,
      ...curr.children.map((c) => {
        index++;
        return {
          ...c,
          index,
          parentSlug: curr.slug,
          tabId: curr.id,
        };
      }),
    ];
  },
  [] as ProductTabSlide[]
);

export default productDetails;
