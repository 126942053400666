import scssStyles from "@/utils/scssStyles";
import { FC, MouseEventHandler, useCallback } from "react";
import styles from "./CTAButton.module.scss";
import { Chevron, ChevronRight } from "../SvgComponents";

interface CTAButtonProps {
  handleClick?: MouseEventHandler<HTMLAnchorElement>;
  handleAuxClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  bgClassName?: string;
  variant?: "secondary" | "primary";
  excludeArrow?: boolean;
  href?: string;
  text: string;
  title: string;
  width?: string;
  style?: React.CSSProperties;
  iconCode?: string;
  larger?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  backgroundColor?: string;
  color?: string;
  download?: boolean;
  chevronComparative?: boolean;
  chevronComparativeColor?: string;
  chevronComparativeDirection?: "left" | "right" | "down" | "up" | undefined;
}

const CTAButton: FC<CTAButtonProps> = ({
  className = "",
  bgClassName = "",
  handleClick,
  handleAuxClick,
  excludeArrow = false,
  larger = false,
  width = "100%",
  target = "_blank",
  title,
  style = {},
  variant = "primary",
  href,
  text,
  iconCode = "",
  backgroundColor,
  color,
  download,
  chevronComparative,
  chevronComparativeColor,
  chevronComparativeDirection,
}) => {
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case "secondary":
        return styles.secondary;
      default:
        return "";
    }
  }, [variant]);
  return (
    <a
      title={title}
      href={href}
      download={download}
      target={target}
      className={scssStyles([
        styles.button,
        className,
        getVariantClass(),
        larger ? styles.larger : "",
      ])}
      onClick={handleClick}
      onAuxClick={handleAuxClick}
      style={{
        ...style,
        width,
      }}
    >
      <div
        className={styles.contentHolder}
        style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}
      >
        <p
          data-icon-code={iconCode}
          className={scssStyles([
            styles.content,
            iconCode ? styles.withIcon : "",
          ])}
          style={{ color: color ? color : "" }}
        >
          {text}
        </p>
        {!excludeArrow && <ChevronRight />}
        {chevronComparative && (
          <Chevron
            direction={chevronComparativeDirection}
            color={chevronComparativeColor}
          />
        )}
      </div>
      <p className={scssStyles([styles.bg, bgClassName])} />
    </a>
  );
};

export default CTAButton;
