import {formatString} from '@/utils/DataLayer';
import {PREFIX} from '@/utils/imagePrefixes';
import {COMPRE_TITLE, MONTE_TITLE, links} from './menu.data';
import {PulseTextDesignProps} from '@/components/pulse-text-design';
import {COLORS} from '@/styles/variables';
import type {JSX} from 'react'

export interface IColors {
    colorCode: string;
    colorName: string;
    colorRGB: string;
    colorNameFormatted: string;
    pathSrc: string;
    colorActive: boolean;
}

export enum EMotorType {
    FLEX = 'FLEX',
    DIESEL = 'DIESEL',
    HÍBRIDO = 'HÍBRIDO',
    ELÉTRICO = 'ELÉTRICO',
}

export interface IVersionsData {
    id: number;
    mvsCode: string;
    slug: string;
    model: {
        shortname: string;
        fullname: string;
        motorizacao: string;
        tabs: PulseTextDesignProps;
        titleTag: string;
        altTag: string;
        cardName: string;
    };
    isTurbo?: boolean,
    isAbarth?: boolean;
    type?: string;
    description: string;
    optionals: {
        text: string;
    }[];
    colors: IColors[];
    price: number;
    path: string;
    pcd?: boolean;
    versionCtaButton: {
        id: string;
        label: string;
        url: string;
        backgroundColor: string;
        color: string;
        download?: boolean;
        excludeArrow?: boolean;
        iconCode?: string;
    }[];
}

export interface IVersionsReference {
    code: string;
    title: string;
    srcFolder: string;
    motor: EMotorType;
}

interface IVersionCodeColor {
    [key: string]: string;
}

interface IVersionCodeColorCapture {
    [key: string]: IVersionCodeColor;
}

const VERSIONS_CTA_STYLES = [
    {backgroundColor: '#FFE8C1', color: '#FC1430'},
    {backgroundColor: '#EDEDE3', color: '#FC1430'},
];

const VERSIONS_ASSET_PREFIX = `${PREFIX}/Versions/`;
export const VERSION_YEAR_BASE = '2025';

export const VERSIONS_REFERENCE: IVersionsReference[] = [

    {
        code: '376AT10',
        title: 'TURBO 200 AT',
        srcFolder: 'fastback-turbo-at',
        motor: EMotorType.FLEX,
    },
    {
        code: '376AV10',
        title: 'AUDACE T200 AT',
        srcFolder: 'fastback-audace',
        motor: EMotorType.FLEX,
    },
    {
        code: '376AX10',
        title: 'IMPETUS T200 AT',
        srcFolder: 'fastback-impetus',
        motor: EMotorType.FLEX,
    },
    {
        code: '376A3B0',
        title: 'LIMITED T270 AT',
        srcFolder: 'limited-edition',
        motor: EMotorType.FLEX,
    },
    {
        code: '376AH70',
        title: 'ABARTH T270 AT',
        srcFolder: 'fastback-abarth',
        motor: EMotorType.FLEX,
    },
];

export const VERSIONS_DATA: IVersionsData[] = [
  {
    id: 0,
    mvsCode: VERSIONS_REFERENCE[0].code,
    slug: formatString(`${VERSIONS_REFERENCE[0].title}`),
    model: {
      fullname: `${VERSIONS_REFERENCE[0].title}`,
      shortname: `${VERSIONS_REFERENCE[0].title}`,
      motorizacao: `${VERSIONS_REFERENCE[0].motor}`,
      cardName: "turbo",
      titleTag: "Fastback Abarth",
      altTag:
        "Fastback Abarth vermelho sobre fundo com grafismo de escorpião em tons de vermelho.",
      tabs: {
        text: `${VERSIONS_REFERENCE[0].title}`,
        backLayer: {
          backgroundColor: COLORS.VERSIONS.BORDO,
          borderColor: COLORS.VERSIONS.MARINE,
        },
        frontLayer: {
          color: COLORS.VERSIONS.MARINE,
          backgroundColor: COLORS.VERSIONS.DEFAULT,
          borderColor: COLORS.VERSIONS.MARINE,
        },
      },
    },
    type: "normal",
    isTurbo: true,
    description:
      "",
    optionals: [
      {
        text: 'Central Multimídia 8,4"',
      },
      {
        text: "Ar-condicionado Automático Digital",
      },
      {
        text: "Freio de Estacionamento Eletrônico",
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/fastback-turbo-at/249.webp`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[0].code}&year=2025#versao`,
        backgroundColor: VERSIONS_CTA_STYLES[0].backgroundColor,
        color: VERSIONS_CTA_STYLES[0].color,
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: VERSIONS_CTA_STYLES[1].backgroundColor,
        color: VERSIONS_CTA_STYLES[1].color,
        excludeArrow: false,
      },
    ],
  },
  {
    id: 1,
    mvsCode: VERSIONS_REFERENCE[1].code,
    slug: formatString(`${VERSIONS_REFERENCE[1].title}`),
    model: {
      fullname: `${VERSIONS_REFERENCE[1].title}`,
      shortname: `${VERSIONS_REFERENCE[1].title}`,
      motorizacao: `${VERSIONS_REFERENCE[1].motor}`,
      cardName: "audace",
      titleTag: "Fastback Abarth",
      altTag:
        "Fastback Abarth vermelho sobre fundo com grafismo de escorpião em tons de vermelho.",
      tabs: {
        text: `${VERSIONS_REFERENCE[1].title}`,
        backLayer: {
          backgroundColor: COLORS.VERSIONS.BORDO,
          borderColor: COLORS.VERSIONS.MARINE,
        },
        frontLayer: {
          color: COLORS.VERSIONS.MARINE,
          backgroundColor: COLORS.VERSIONS.DEFAULT,
          borderColor: COLORS.VERSIONS.MARINE,
        },
      },
    },
    type: "normal",
    description:
      "",
    optionals: [
      {
        text: "Frenagem autônoma de emergência",
      },
      {
        text: "Alerta de saída de faixa",
      },
      {
        text: "Freio de estacionamento eletrônico",
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/fastback-audace/806.webp`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[1].code}&year=2025#versao`,
        backgroundColor: VERSIONS_CTA_STYLES[0].backgroundColor,
        color: VERSIONS_CTA_STYLES[0].color,
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: VERSIONS_CTA_STYLES[1].backgroundColor,
        color: VERSIONS_CTA_STYLES[1].color,
        excludeArrow: false,
      },
    ],
  },
  {
    id: 2,
    mvsCode: VERSIONS_REFERENCE[2].code,
    slug: formatString(`${VERSIONS_REFERENCE[2].title}`),
    model: {
      fullname: `${VERSIONS_REFERENCE[2].title}`,
      shortname: `${VERSIONS_REFERENCE[2].title}`,
      motorizacao: `${VERSIONS_REFERENCE[2].motor}`,
      cardName: "impetus",
      titleTag: "Fastback Abarth",
      altTag:
        "Fastback Abarth vermelho sobre fundo com grafismo de escorpião em tons de vermelho.",
      tabs: {
        text: `${VERSIONS_REFERENCE[2].title}`,
        backLayer: {
          backgroundColor: COLORS.VERSIONS.BORDO,
          borderColor: COLORS.VERSIONS.MARINE,
        },
        frontLayer: {
          color: COLORS.VERSIONS.MARINE,
          backgroundColor: COLORS.VERSIONS.DEFAULT,
          borderColor: COLORS.VERSIONS.MARINE,
        },
      },
    },
    type: "normal",
    description:
      "",
    optionals: [
      {
        text: 'Central Multimídia 10,1"',
      },
      {
        text: 'Painel de Instrumentos Digital 7"',
      },
      {
        text: 'Rodas de 18"',
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/fastback-impetus/175.webp`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[2].code}&year=2025#versao`,
        backgroundColor: VERSIONS_CTA_STYLES[0].backgroundColor,
        color: VERSIONS_CTA_STYLES[0].color,
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: VERSIONS_CTA_STYLES[1].backgroundColor,
        color: VERSIONS_CTA_STYLES[1].color,
        excludeArrow: false,
      },
    ],
  },
  {
    id: 3,
    mvsCode: VERSIONS_REFERENCE[3].code,
    slug: formatString(`${VERSIONS_REFERENCE[3].title}`),
    model: {
      fullname: `${VERSIONS_REFERENCE[3].title}`,
      shortname: `${VERSIONS_REFERENCE[3].title}`,
      motorizacao: `${VERSIONS_REFERENCE[3].motor}`,
      cardName: "Limited edition",
      titleTag: "Fastback Abarth",
      altTag:
        "Fastback Abarth vermelho sobre fundo com grafismo de escorpião em tons de vermelho.",
      tabs: {
        text: `${VERSIONS_REFERENCE[3].title}`,
        backLayer: {
          backgroundColor: COLORS.VERSIONS.BORDO,
          borderColor: COLORS.VERSIONS.MARINE,
        },
        frontLayer: {
          color: COLORS.VERSIONS.MARINE,
          backgroundColor: COLORS.VERSIONS.DEFAULT,
          borderColor: COLORS.VERSIONS.MARINE,
        },
      },
    },
    isAbarth: true,
    type: "limited",
    description:
      "",
    optionals: [
      {
        text: "Celular carregado e espelhado sem fio",
      },
      {
        text: "Porta-malas gigante de 600 litros",
      },
      {
        text: "Motor Turbo de 185CV Super Potente",
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/limited-edition/743.webp`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[3].code}&year=2025#versao`,
        backgroundColor: VERSIONS_CTA_STYLES[0].backgroundColor,
        color: VERSIONS_CTA_STYLES[0].color,
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: VERSIONS_CTA_STYLES[1].backgroundColor,
        color: VERSIONS_CTA_STYLES[1].color,
        excludeArrow: false,
      },
    ],
  },
  {
    id: 4,
    mvsCode: VERSIONS_REFERENCE[4].code,
    slug: formatString(`${VERSIONS_REFERENCE[4].title}`),
    model: {
      fullname: `${VERSIONS_REFERENCE[4].title}`,
      shortname: `${VERSIONS_REFERENCE[4].title}`,
      motorizacao: `${VERSIONS_REFERENCE[4].motor}`,
      cardName: "Fastback Abarth",
      titleTag: "Fastback Abarth",
      altTag:
        "Fastback Abarth vermelho sobre fundo com grafismo de escorpião em tons de vermelho.",
      tabs: {
        text: `${VERSIONS_REFERENCE[4].title}`,
        backLayer: {
          backgroundColor: COLORS.VERSIONS.BORDO,
          borderColor: COLORS.VERSIONS.MARINE,
        },
        frontLayer: {
          color: COLORS.VERSIONS.MARINE,
          backgroundColor: COLORS.VERSIONS.DEFAULT,
          borderColor: COLORS.VERSIONS.MARINE,
        },
      },
    },
    type: "abarth",
    description:
      "",
    optionals: [
      {
        text: "Motor T270 ainda mais envenenado",
      },
      {
        text: 'RODAS ESPORTIVAS 18"',
      },
      {
        text: "MODO POISON",
      },
    ],
    colors: [],
    price: 0,
    path: `${PREFIX}Versions/fastback-abarth/663.webp`,
    versionCtaButton: [
      {
        id: "ctaButton-0",
        label: MONTE_TITLE,
        url: `${links.monte}?mvs=${VERSIONS_REFERENCE[4].code}&year=2025#versao`,
        backgroundColor: VERSIONS_CTA_STYLES[0].backgroundColor,
        color: VERSIONS_CTA_STYLES[0].color,
        excludeArrow: false,
      },
      {
        id: "ctaButton-1",
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: VERSIONS_CTA_STYLES[1].backgroundColor,
        color: VERSIONS_CTA_STYLES[1].color,
        excludeArrow: false,
      },
    ],
  },
];
const VERSION_COLOR_IMAGE: IVersionCodeColorCapture = {
    // fastback-turbo-at
    '376AT10': {
        '249': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[0].srcFolder}/249.webp`,
        '540': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[0].srcFolder}/540.webp`,
        '619': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[0].srcFolder}/619.webp`,
        '806': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[0].srcFolder}/806.webp`,
        '979': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[0].srcFolder}/979.webp`,
    },
    // fastback-audace-at
    '376AV10': {
        '249': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[1].srcFolder}/249.webp`,
        '540': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[1].srcFolder}/540.webp`,
        '619': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[1].srcFolder}/619.webp`,
        '806': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[1].srcFolder}/806.webp`,
        '979': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[1].srcFolder}/979.webp`,
    },
    // fastback-impetus-at
    '376AX10': {
        '175': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[2].srcFolder}/175.webp`,
        '743': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[2].srcFolder}/743.webp`,
        '766': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[2].srcFolder}/766.webp`,
        '806': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[2].srcFolder}/806.webp`,
        '832': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[2].srcFolder}/832.webp`,
    },
    // fastback-limited-at
    '376A3B0': {
        '175': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[3].srcFolder}/175.webp`,
        '743': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[3].srcFolder}/743.webp`,
        '766': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[3].srcFolder}/766.webp`,
        '806': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[3].srcFolder}/806.webp`,
        '832': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[3].srcFolder}/832.webp`,
    },
    // fastback-abarth-at
    '376AH70': {
        '663': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[4].srcFolder}/663.webp`,
        '743': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[4].srcFolder}/743.webp`,
        '766': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[4].srcFolder}/766.webp`,
        '806': `${VERSIONS_ASSET_PREFIX}${VERSIONS_REFERENCE[4].srcFolder}/806.webp`,
    },


};

export const getVersionImageColor = (
    mvs_code: string,
    color_code: string
): string => {
    return (
        VERSION_COLOR_IMAGE[mvs_code]?.[color_code] ||
        VERSION_COLOR_IMAGE[mvs_code]?.['249'] ||
        `${VERSIONS_ASSET_PREFIX}fastback-turbo-at/249.webp`
    );
};

export const getVersionsModelURL = (year_base: string) =>
    `https://mvs-service.k8s.fcalatam.com.br/mvs?brand=FIAT&model=376&year=${year_base}&market=pt-BR&versionsShown=&excludeGrouped=true`;

export const getVersionsColorURL = (mvs_code: string, year_base: string) =>
    `https://part-service.k8s.fcalatam.com.br/v2/color?mvsCode=${mvs_code}&year=${year_base}&market=pt-BR`;

export const getVersionsPriceURL = (mvs_code: string, year_base: string) =>
    `https://price-service.k8s.fcalatam.com.br/price?mvsCode=${mvs_code}&year=${year_base}&country=BR&state=SP&excludeParts=true`;

export const handleReturnDefaultColorByVersion = (mvs_code?: string) => {
    switch (mvs_code) {
        case '376AT10':
            return '249';
        case '376AV10':
            return '806';
        case '376AX10':
            return '175';
        case '376A3B0':
            return '743';
        case '376AH70':
            return '663';
        default:
            return '249';
    }
};
