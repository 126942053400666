import { FC } from "react";

const Detail: FC<{ color?: string; title?: string }> = ({
  color = "#FFFFFF",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200.173"
      height="11.046"
      viewBox="0 0 200.173 11.046"
    >
      <g id="Group_4321" data-name="Group 4321" transform="translate(0)">
        <path
          id="Path_4630"
          data-name="Path 4630"
          d="M261.584,0,258,11.046h1.659L263.244,0Z"
          transform="translate(-111.803)"
          fill={color}
        />
        <path
          id="Path_4631"
          data-name="Path 4631"
          d="M304.584,0,301,11.046h1.659L306.244,0Z"
          transform="translate(-130.436)"
          fill={color}
        />
        <path
          id="Path_4632"
          data-name="Path 4632"
          d="M347.584,0,344,11.046h1.659L349.243,0Z"
          transform="translate(-149.07)"
          fill={color}
        />
        <path
          id="Path_4633"
          data-name="Path 4633"
          d="M132.584,0,129,11.046h1.659L134.243,0Z"
          transform="translate(-55.901)"
          fill={color}
        />
        <path
          id="Path_4634"
          data-name="Path 4634"
          d="M3.584,0,0,11.046H1.659L5.243,0Z"
          fill={color}
        />
        <path
          id="Path_4635"
          data-name="Path 4635"
          d="M46.584,0,43,11.046H44.66L48.244,0Z"
          transform="translate(-18.634)"
          fill={color}
        />
        <path
          id="Path_4636"
          data-name="Path 4636"
          d="M89.584,0,86,11.046h1.659L91.244,0Z"
          transform="translate(-37.268)"
          fill={color}
        />
        <path
          id="Path_4637"
          data-name="Path 4637"
          d="M175.584,0,172,11.046h1.659L177.243,0Z"
          transform="translate(-74.535)"
          fill={color}
        />
        <path
          id="Path_4638"
          data-name="Path 4638"
          d="M218.584,0,215,11.046h1.659L220.244,0Z"
          transform="translate(-93.169)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Detail;
