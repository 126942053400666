export enum EComparativeStatus {
  'OPEN',
  'CLOSE',
}

interface IComparativeContext {
  get_comparative_status: EComparativeStatus;
  set_comparative_status: (status: EComparativeStatus) => void;
}

export interface IComparativeContextType {
  COMPARATIVE_CONTEXT: IComparativeContext;
}

export const COMPARATIVE_INITIAL_VALUE: IComparativeContextType = {
  COMPARATIVE_CONTEXT: {
    get_comparative_status: EComparativeStatus.CLOSE,
    set_comparative_status: () => null,
  },
};
