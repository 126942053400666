import * as React from 'react';
const ScorpionSvg: React.FC<{ fill?: string }> = ({ fill = '#000' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 129.902 149.069"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4904"
          data-name="Rectangle 4904"
          width="100%"
          height="100%"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="logo-abarth-scorpion" clipPath="url(#clip-path)">
      <path
        id="Path_5012"
        data-name="Path 5012"
        d="M124.552,6.341c6.447-.915,12.324-1.773,18.208-2.58C151.84,2.515,160.928,1.333,170,.036a2.219,2.219,0,0,1,2.3.95q14.5,17.121,29.059,34.2c.744.873,1.231,1.31-.258,2.061-15.049,7.591-30.054,15.275-45.073,22.929-.238.122-.492.211-.839.356-1.192-4.528-2.279-9-3.578-13.4-.492-1.667.694-1.474,1.393-1.682q14.895-4.438,29.815-8.788c4.106-1.2,4.1-1.181,2.2-5.143-1.58-3.281-3.143-6.571-4.778-9.993-3.371,3.133-6.595,6.117-9.8,9.12a2.344,2.344,0,0,1-2,.9c-9.245-1.042-18.495-2.036-27.742-3.051a4.413,4.413,0,0,1-.761-.279l17.884-7.9L124.552,6.341"
        transform="translate(-72.166 0)"
        fill="currentColor"
      />
      <path
        id="Path_5013"
        data-name="Path 5013"
        d="M56.351,96.29,9.108,120.37c-1.753-9-3.472-17.821-5.188-26.64C2.654,87.222,1.4,80.71.115,74.207a2.6,2.6,0,0,1,.638-2.7Q16.406,55.472,31.972,39.345a1.275,1.275,0,0,1,1.015-.383L23.731,73.9,39.07,64.62c.463,1.086-.2,1.588-.537,2.158C34.257,74.053,29.926,81.294,25.7,88.6a4.05,4.05,0,0,1-3.08,2.171c-3.785.672-7.552,1.458-11.587,2.249,2.148,4.475,4.22,8.824,6.323,13.155.638,1.315,1.186.1,1.587-.207,4.743-3.579,9.452-7.207,14.167-10.825Q39.68,90.1,46.238,85.049c.738-.572,1.206-.948,1.962.124,2.59,3.675,5.3,7.256,8.152,11.116"
        transform="translate(0 -22.051)"
        fill="currentColor"
      />
      <path
        id="Path_5014"
        data-name="Path 5014"
        d="M163.291,138.887c-.305,1.947-.584,3.9-.927,5.84-.183,1.033-1.112.724-1.717.783-3.829.378-7.661.744-11.5.981-1.5.092-1.975.285-1.6,2.115.487,2.359,1.48,2.96,3.681,2.694,3.677-.444,7.384-.7,11.084-.838,1.5-.055,1.8-1.421,2.928-2.109.266,2.081-.383,3.991-.471,5.932-.047,1.051-.991.806-1.639.862-3.833.332-7.665.718-11.5.924-1.645.089-1.778.588-1.47,2.179.452,2.331,1.436,3,3.7,2.665a77.321,77.321,0,0,1,8.578-.595c1.944-.071,3.684-.431,5.029-2.222-.133,1.838-.286,3.674-.391,5.513-.071,1.238-.969.994-1.69,1.066-4.306.428-8.613.848-12.949,1.273,1.6,6.3.3,4.808,5.87,4.7,2.026-.041,4.052-.225,6.07-.427,1.749-.175,3.7.073,4.857-2.666a23.126,23.126,0,0,1-.262,6.331c-.1.889-1.145.636-1.784.7-3.479.373-6.963.689-10.446,1.021-.939.089-2,.049-1.195,1.572.47.892-.2,1.3-.777,1.728-1.756,1.313-3.37,2.939-5.3,3.868-2.785,1.34-5.832,2.082-8.934,1.092-.917-.292-1.1.408-1.453.783-2.524,2.7-5.03,5.428-7.463,8.218-.746.855-1.3,1.038-2.238.328-1.231-.931-2.57-1.709-3.984-2.8,3.05.338,4.141-2.14,5.705-3.9,1.658-1.86,3.175-3.861,4.9-5.65,1.119-1.161,1.03-1.964.064-3.17-1.91-2.385-1.842-2.447-3.853-.222-2.338,2.588-4.7,5.158-6.992,7.785-.728.833-1.3,1.06-2.249.309-1.255-.995-2.629-1.83-4-2.974,2.643.451,3.8-1.457,5.155-3.067,1.925-2.292,3.823-4.613,5.838-6.818,1.017-1.113,1.227-1.9.168-3.191-1.8-2.2-1.7-2.287-3.519-.232-2.4,2.72-4.845,5.408-7.174,8.195-.864,1.035-1.539,1.111-2.591.349-1.249-.9-2.613-1.639-4.05-2.7,3.515.256,4.581-2.79,6.408-4.747,1.795-1.922,3.375-4.058,5.176-5.973.928-.987.871-1.639.086-2.664-1.909-2.489-1.854-2.524-3.852-.254-2.446,2.778-4.921,5.529-7.344,8.329-.641.741-1.168,1.037-2.1.435-1.446-.935-2.982-1.722-4.488-2.795,2.307.44,3.365-1.06,4.39-2.631.352-.539.789-1.022,1.1-1.581a30.928,30.928,0,0,1,15.735-13.8,152.919,152.919,0,0,0,14.648-7.444,11.544,11.544,0,0,1,6.393-1.529c3.28.186,6.575.111,9.849.353a4.86,4.86,0,0,0,4.492-1.929"
        transform="translate(-61.811 -78.604)"
        fill="currentColor"
      />
      <path
        id="Path_5015"
        data-name="Path 5015"
        d="M146.218,112.192c.218.21.484.439.721.7,3.229,3.506,6.42,7.051,9.7,10.5.9.946.707,1.258-.319,1.774q-11.29,5.682-22.541,11.445c-.812.414-1.454.9-1.663-.649-.646-4.785-1.379-9.559-2.151-14.324-.19-1.172.084-1.514,1.236-1.49,2.167.044,4.34-.161,6.5-.083a3.287,3.287,0,0,0,3.17-1.536c1.3-1.876,2.772-3.626,4.192-5.413.292-.368.477-.911,1.146-.921"
        transform="translate(-75.273 -63.496)"
        fill="currentColor"
      />
      <path
        id="Path_5016"
        data-name="Path 5016"
        d="M203.732,233.189c0,7.033-.016,13.669.017,20.306.006,1.01-.14,1.529-1.3,1.619-2.851.22-5.684.689-8.536.911-2.231.174-4.965-2.59-4.966-4.834,0-3.39.086-6.785-.039-10.171-.057-1.55.522-2.109,1.878-2.041a14.338,14.338,0,0,0,10.641-4.127,27.707,27.707,0,0,1,2.3-1.662"
        transform="translate(-109.453 -131.974)"
        fill="currentColor"
      />
      <path
        id="Path_5017"
        data-name="Path 5017"
        d="M191.128,284.6c-5.395,6.367-10.409,12.272-15.4,18.2-.6.714-1.045.705-1.713.078-1.55-1.457-3.207-2.792-4.745-4.262a5.3,5.3,0,0,1-.527-7.012q3.175-4.029,6.363-8.048c.425-.536.831-1.313,1.615-.5,3.1,3.219,6.886,2.332,10.6,1.909,1.1-.125,2.2-.211,3.809-.364"
        transform="translate(-97.143 -159.998)"
        fill="currentColor"
      />
      <path
        id="Path_5018"
        data-name="Path 5018"
        d="M61.114,264.093l8.825-33.217.381.049c1.2,8.869,2.375,17.741,3.622,26.6.137.972-.242,1.268-.956,1.567-3.863,1.612-7.717,3.247-11.873,5"
        transform="translate(-35.41 -130.665)"
        fill="currentColor"
      />
      <path
        id="Path_5019"
        data-name="Path 5019"
        d="M152.6,319.707c-6,1.364-11.833,2.7-17.675,4.009-.511.115-1.256.413-1.483-.313-.806-2.571-2.612-5.048-.719-7.928.394-.6.62-1.234,1.349-1.52q5.183-2.039,10.344-4.138c.551-.224,1.15-.551,1.246.352.449,4.229,3.838,6.138,6.363,8.734a7.521,7.521,0,0,1,.576.8"
        transform="translate(-76.415 -175.202)"
        fill="currentColor"
      />
      <path
        id="Path_5020"
        data-name="Path 5020"
        d="M82.839,300.843c1.606-.666,2.958-1.191,4.28-1.79.7-.318,1.178.042,1.719.374,4.494,2.763,9,5.5,13.486,8.28.523.324,1.553.549.9,1.529-1.716,2.57-.718,5.051.1,7.556a1.675,1.675,0,0,1,.065,1.456L82.839,300.843"
        transform="translate(-47.997 -169.181)"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ScorpionSvg;
