import { IconButton } from "@/components";
import { brandLinks, STORAGE_PREFIX } from "@/constants";
import { FC, useEffect } from "react";
import styles from "./CookieHolder.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import RampageStore from "@/store/RampageStore";
import { useMobxStores } from "@/store";
import { observer } from "mobx-react-lite";
import DataLayer from "@/utils/DataLayer";
import { Close } from "@/components/SvgComponents";

const pageSubsection = "lgpd-caixa-inicial";

export const CookieHolder: FC = observer(() => {
  const store: RampageStore = useMobxStores();
  const handleClose = () => {
    window.localStorage.setItem(`${STORAGE_PREFIX}lgpd`, "true");
    store.setShowCookieInfo(false);
  };

  useEffect(() => {
    if (window?.localStorage) {
      store.setShowCookieInfo(
        !window.localStorage.getItem(`${STORAGE_PREFIX}lgpd`)
      );
    }
  }, [store]);

  const tracking = () => {
    DataLayer.clickEvent({
      element: "política de privacidade",
      elementCategory: "link",
      pageSection: "conteudo",
      pageSubsection,
    });
  };

  return (
    <AnimatePresence>
      {store.showCookieInfo && (
        <motion.div
          initial={{
            y: 100,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: 100,
            opacity: 0,
          }}
          transition={{
            duration: 0.4,
          }}
          className={styles.container}
        >
          <div>
            <h3 className="title">RESPEITAMOS A SUA PRIVACIDADE</h3>
            <p>
              {
                "Informamos que utilizamos cookies que nos permitem fornecer funcionalidades como segurança, acessibilidade e gerenciamento de rede. Nosso site também pode usar cookies de terceiros para enviar publicidade mais relevante para você. Se quiser saber mais sobre os cookies que usamos para garantir uma melhor experiência em nosso site, acesse nossa "
              }
              <a
                className={styles.bannerLink}
                href={brandLinks.privacyPolicy}
                target="_blank"
                title="Política de Privacidade"
                onClick={() => tracking()}
                onAuxClick={() => tracking()}
              >
                {"Política de Privacidade"}
              </a>
              {"."}
            </p>
            <IconButton
              icon={<Close />}
              className={styles.closeButton}
              handleClick={() => {
                handleClose();
              }}
              title="Fechar"
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

