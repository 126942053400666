import { useSwipeable } from "react-swipeable";
import {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion";
import styles from "./Stories.module.scss";
import regex from "@utils/testRegex";
import useLongPressDiv from "@/hooks/useLongPressDiv";
import { StoriesBackgroundStyleProps, StoriesItemProps } from "@/models";
import scssStyles from "@/utils/scssStyles";
import { dataMenuLabels } from "@/data/menu.data";
import { Context } from "@/context";
import Image from "next/image";
import DataLayer from "@utils/DataLayer";
import { Chevron, LoadingFlag, Pointer } from "../SvgComponents";
import ScrollToAnchor from "../ScrollToAnchor";
import { NewCta } from "../new-cta";

interface StoryProps {
  story: StoriesItemProps;
  swipingNext: boolean;
  index: number;
  currentIndex: number;
  style: StoriesBackgroundStyleProps;
  next: () => void;
  previous: () => void;
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const Story: FC<StoryProps> = ({
  story,
  index,
  swipingNext,
  currentIndex,
  style,
  state,
  next,
  previous,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const active = index === currentIndex;
  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.defaultMuted = true;
      videoRef.current.muted = true;
    }
  }, [videoRef]);

  const longPress = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    videoRef?.current?.pause();
    state[1](false);
    e.preventDefault();
  };

  const clear = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    videoRef?.current?.play();
    state[1](true);
    e.preventDefault();
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      DataLayer.swipeEvent({
        elementCategory: "stories",
        element: "proximo",
        pageSection: "conteudo",
        pageSubsection: "fiat-fastback",
      });
      next();
    },
    onSwipedRight: () => {
      DataLayer.swipeEvent({
        elementCategory: "stories",
        element: "anterior",
        pageSection: "conteudo",
        pageSubsection: "fiat-fastback",
      });
      previous();
    },
  });

  const {
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    onTouchCancel,
  } = useLongPressDiv(
    {
      onLongPress: longPress,
      onClear: clear,
    },
    {
      delay: 400,
    }
  );

  const animate = useCallback(() => {
    if (active)
      return {
        opacity: 1,
        left: "0%",
      };
    if (swipingNext)
      return {
        opacity: 0,
        left: index < currentIndex ? "-100%" : "100%",
      };
    return {
      opacity: 0,
      left: index < currentIndex ? "-100%" : "100%",
    };
  }, [active, swipingNext, currentIndex, index]);

  return (
    <motion.div
      {...swipeHandlers}
      key={`story-l-${index}`}
      className={styles.story}
      onMouseDown={(e) => onMouseDown(e)}
      onMouseLeave={(e) => onMouseLeave(e)}
      onMouseUp={(e) => onMouseUp(e)}
      onTouchEnd={(e) => onTouchEnd(e)}
      onTouchStart={(e) => onTouchStart(e)}
      onTouchCancel={(e) => onTouchCancel(e)}
      layoutId={`story-v-v-${index}`}
      initial={
        !active
          ? {
              opacity: 0,
            }
          : undefined
      }
      animate={animate()}
      transition={{
        left: { type: "spring", stiffness: 300, damping: 30 },
        duration: 0.2,
      }}
    >
      <div className={styles.imageHolder} style={style}>
        {/* Rever esta logica para !== 0 apenas quando todos  os demais destaques sejam videos */}
        {index === 2 ? (
          <>
            <LoadingFlag />
            <video
              ref={videoRef}
              autoPlay={true}
              muted
              loop={true}
              playsInline
              className={styles.video}
            >
              <source src={story.teste ?? ""} />
            </video>
            {story.body}
          </>
        ) : (
          <motion.div
            {...swipeHandlers}
            key={`story-${index}`}
            className={styles.story}
            initial={{
              x: swipingNext ? "100%" : "-100%",
            }}
            animate={{
              x: "0%",
            }}
            exit={{
              x: swipingNext ? "-100%" : "100%",
            }}
            transition={{
              ease: "easeInOut",
              delay: 0.1,
              duration: 0.2,
            }}
          >
            <Image
              className={styles.imageHolder}
              alt={story.altTag}
              title={story.titleTag}
              src={story.teste}
              layout="fill"
              priority
            />
            {story.body}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

interface StoriesProps {
  items: StoriesItemProps[];
}

const Stories: FC<StoriesProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swipingNext, setSwipingNext] = useState(true);
  const playControl = useState(true);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { state } = useContext(Context);

  useEffect(() => {
    if (timer?.current) {
      clearTimeout(timer.current);
    }
    if (wrapperRef) {
      if ((wrapperRef?.current as HTMLDivElement)?.style) {
        (wrapperRef.current as HTMLDivElement).style.backgroundColor =
          items[currentIndex].background?.color ?? "transparent";
      }
    }
    timer.current = setTimeout(() => {
      setSwipingNext(true);
      setCurrentIndex((i) => {
        const nI = i >= items.length - 1 ? 0 : i + 1;
        return nI;
      });
    }, items[0].durationInS * 650);

    return () => {
      if (timer?.current) {
        clearTimeout(timer.current);
      }
    };
  }, [items, currentIndex]);

  const next = () => {
    setSwipingNext(true);
    setCurrentIndex((i) => {
      const nI = i >= items.length - 1 ? 0 : i + 1;
      return nI;
    });
  };

  const previous = () => {
    setSwipingNext(false);
    setCurrentIndex((i) => {
      const nI = i <= 0 ? items.length - 1 : i - 1;
      return nI;
    });
  };

  return (
    <section className={styles.container}>
      <div className={styles.bar}>
        {items.map((story, index) => {
          const hasGone = currentIndex > index;
          const active = currentIndex === index;
          return (
            <div
              key={`story-bar-${index}`}
              className={styles.barItemHolder}
              onClick={() => {
                setSwipingNext(currentIndex < index);
                setCurrentIndex(index);
              }}
            >
              <div
                className={scssStyles([
                  styles.barItem,
                  hasGone ? styles.hasGone : "",
                  active ? styles.hasActive : "",
                ])}
              >
                <AnimatePresence>
                  {active && (
                    <div
                      className={styles.loadinBar}
                      style={{
                        animationDuration: `${story.durationInS}s`,
                        animationPlayState: "running",
                        backgroundColor:'red',
                      }}
                    />
                  )}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </div>
      <div ref={wrapperRef} className={styles.wrapper}>
        <button
          title="Story anterior"
          className={styles.previous}
          onClick={() => {
            DataLayer.clickEvent({
              element: "anterior",
              elementCategory: "stories",
              pageSection: "conteudo",
              pageSubsection: "fiat-fastback",
            });
            previous();
          }}
        />
        <button
          title="Próximo story"
          className={styles.next}
          onClick={() => {
            DataLayer.clickEvent({
              element: "proximo",
              elementCategory: "stories",
              pageSection: "conteudo",
              pageSubsection: "fiat-fastback",
            });
            next();
          }}
        />
        <div className={styles.clickCta}>
          <Pointer />
          {/* <p>Toque e veja as novidades</p> */}
        </div>
        <ScrollToAnchor
          reference={dataMenuLabels()[1]}
          className={scssStyles([
            styles.scrollCta,
            items[currentIndex].scrollCtaOrientation === "vertical"
              ? styles.scrollCtaVertical
              : styles.scrollCtaHorizontal,
          ])}
          pageSubsection="nova-classic"
          title="Arraste para saber mais"
        >
          <p>Arraste para saber mais</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.567"
            height="55.553"
            viewBox="0 0 17.567 55.553"
          >
            <g id="scroll" transform="translate(-435.724 -551.253)">
              <g
                id="Grupo_4031"
                data-name="Grupo 4031"
                transform="translate(-84.363 44.213)"
              >
                <g
                  id="Grupo_4030"
                  data-name="Grupo 4030"
                  transform="translate(517.335 511.787)"
                >
                  <path
                    id="seta"
                    d="M0,0,7.047,7.213,13.976,0"
                    transform="translate(4.539)"
                    fill="none"
                    stroke="#ffb80f"
                    strokeWidth="5"
                  />
                  <path
                    id="seta-2"
                    data-name="seta"
                    d="M0,0,7.047,7.213,13.976,0"
                    transform="translate(4.539 20)"
                    fill="none"
                    stroke="#ffb80f"
                    strokeWidth="5"
                  />
                  <path
                    id="seta-3"
                    data-name="seta"
                    d="M0,0,7.047,7.213,13.976,0"
                    transform="translate(4.539 40)"
                    fill="none"
                    stroke="#ffb80f"
                    strokeWidth="5"
                  />
                </g>
              </g>
              <g
                id="Grupo_4249"
                data-name="Grupo 4249"
                transform="translate(-84.363 41.213)"
              >
                <g
                  id="Grupo_4030-2"
                  data-name="Grupo 4030"
                  transform="translate(517.335 511.787)"
                >
                  <path
                    id="seta-4"
                    data-name="seta"
                    d="M0,0,7.047,7.213,13.976,0"
                    transform="translate(4.539)"
                    fill="none"
                    stroke="#272727"
                    strokeWidth="5"
                  />
                  <path
                    id="seta-5"
                    data-name="seta"
                    d="M0,0,7.047,7.213,13.976,0"
                    transform="translate(4.539 20)"
                    fill="none"
                    stroke="#272727"
                    strokeWidth="5"
                  />
                  <path
                    id="seta-6"
                    data-name="seta"
                    d="M0,0,7.047,7.213,13.976,0"
                    transform="translate(4.539 40)"
                    fill="none"
                    stroke="#272727"
                    strokeWidth="5"
                  />
                </g>
              </g>
            </g>
          </svg>
        </ScrollToAnchor>

        <div className={styles.nextTopic}>
          <div
            className={styles.nextTopicText}
            onClick={() => {
              DataLayer.clickEvent({
                element: "proximo",
                elementCategory: "stories",
                pageSection: "conteudo",
                pageSubsection: "fiat-fastback",
              });
              next();
            }}
          >
            {!!items[currentIndex]?.showNext &&
              items[currentIndex + 1]?.title && (
                <>
                  {items[currentIndex + 1].nextTitle}
                  <svg
                    id="Componente_99_78"
                    data-name="Componente 99 – 78"
                    width="48"
                    height="48"
                  viewBox="0 0 48 48"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Retângulo_2681"
                          data-name="Retângulo 2681"
                          width="48"
                          height="48"
                          transform="translate(67 54)"
                          fill="#ff1430"
                        />
                      </clipPath>
                      <clipPath id="clip-path-2">
                        <rect
                          id="Retângulo_2682"
                          data-name="Retângulo 2682"
                          width="45.911"
                          height="32.039"
                          transform="translate(0 -0.001)"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Grupo_de_máscara_965"
                      data-name="Grupo de máscara 965"
                      transform="translate(-67 -54)"
                      clip-path="url(#clip-path)"
                    >
                      <g
                        id="Grupo_4241"
                        data-name="Grupo 4241"
                        transform="translate(68 62)"
                      >
                        <path
                          id="Caminho_4639"
                          data-name="Caminho 4639"
                          d="M13.075,4.206,4.206,31.538H36.354L45.223,4.206Z"
                          fill="#fff"
                        />
                        <g id="Grupo_4240" data-name="Grupo 4240">
                          <g
                            id="Grupo_4239"
                            data-name="Grupo 4239"
                            clip-path="url(#clip-path-2)"
                          >
                            <path
                              id="Caminho_4640"
                              data-name="Caminho 4640"
                              d="M13.075,4.206,4.206,31.538H36.355L45.223,4.206Z"
                              fill="none"
                              stroke="#000"
                              stroke-miterlimit="10"
                              strokeWidth="1"
                            />
                          </g>
                        </g>
                        <path
                          id="Caminho_4641"
                          data-name="Caminho 4641"
                          d="M8.869,0,0,27.332H32.148L41.017,0Z"
                        />
                        <g id="Grupo_4295" data-name="Grupo 4295">
                          <rect
                            id="Retângulo_2683"
                            data-name="Retângulo 2683"
                            width="3.257"
                            height="12.306"
                            transform="translate(13.531 13.94) rotate(-46.959)"
                            fill="#fff"
                          />
                          <rect
                            id="Retângulo_2684"
                            data-name="Retângulo 2684"
                            width="12.306"
                            height="3.257"
                            transform="translate(13.531 13.94) rotate(-46.959)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <g
                        id="Caminho_4746"
                        data-name="Caminho 4746"
                        transform="translate(71 59)"
                      >
                        <path
                          d="M 42.5 42.5 L 0.5 42.5 L 0.5 0.5 L 42.5 0.5 L 42.5 42.5 Z"
                          stroke="none"
                        />
                        <path
                          d="M 1 1 L 1 42 L 42 42 L 42 1 L 1 1 M 0 0 L 43 0 L 43 43 L 0 43 L 0 0 Z"
                          stroke="none"
                          fill="#000"
                        />
                      </g>
                      <g
                        id="Caminho_4687"
                        data-name="Caminho 4687"
                        transform="translate(67 54)"
                        fill="#fff"
                      >
                        <path
                          d="M 42.5 42.5 L 0.5 42.5 L 0.5 0.5 L 42.5 0.5 L 42.5 42.5 Z"
                          stroke="none"
                        />
                        <path
                          d="M 1 1 L 1 42 L 42 42 L 42 1 L 1 1 M 0 0 L 43 0 L 43 43 L 0 43 L 0 0 Z"
                          stroke="none"
                          fill="#000"
                        />
                      </g>
                      <g
                        id="Grupo_4301"
                        data-name="Grupo 4301"
                        transform="translate(68 62)"
                      >
                        <g
                          id="Grupo_4295-2"
                          data-name="Grupo 4295"
                          transform="translate(40.278 27.286) rotate(180)"
                        >
                          <rect
                            id="Retângulo_2683-2"
                            data-name="Retângulo 2683"
                            width="3.257"
                            height="12.306"
                            transform="translate(13.531 13.94) rotate(-46.959)"
                          />
                          <rect
                            id="Retângulo_2684-2"
                            data-name="Retângulo 2684"
                            width="12.306"
                            height="3.257"
                            transform="translate(13.531 13.94) rotate(-46.959)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </>
              )}
          </div>
        </div>

        <AnimateSharedLayout>
          {items.map((story, index) => {
            const getBgs = () => {
              if (state.layout.pageX > 1024) {
                return story.background?.src?.fullPath3x;
              }

              if (state.layout.pageX < 768) {
                return story.background?.src?.fullPath;
              }
              return story.background?.src?.fullPath2x;
            };
            const style: StoriesBackgroundStyleProps = {
              backgroundImage:
                story.background?.src &&
                regex.testImage(story.background.src.fullPath)
                  ? `url('${getBgs()}')`
                  : "unset",
              backgroundColor: story.background?.color
                ? story.background.color
                : "unset",
            };
            if (story.background?.style?.backgroundPosition) {
              style["backgroundPosition"] =
                story.background?.style?.backgroundPosition;
            }
            if (story.background?.style?.backgroundSize) {
              style["backgroundSize"] = story.background?.style?.backgroundSize;
            }
            if (story.background?.style?.backgroundRepeat) {
              style["backgroundRepeat"] =
                story.background?.style?.backgroundRepeat;
            }
            return (
              <Story
                key={`story---${index}`}
                story={story}
                index={index}
                style={style}
                previous={previous}
                next={next}
                currentIndex={currentIndex}
                swipingNext={swipingNext}
                state={playControl}
              />
            );
          })}
        </AnimateSharedLayout>
        {!!items[currentIndex]?.includeGradient && (
          <div className={styles.gradient} />
        )}
      </div>
    </section>
  );
};

export default Stories;
