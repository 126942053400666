import "@styles/globals.scss";
import "swiper/css";
import dynamic from "next/dynamic";
import { getStore, StoreProvider } from "@store/index";
import { useEffect } from "react";
import Head from "next/head";
import NextApp, { AppContext, AppProps } from "next/app";
import { StickyCTA } from "@/components";
import { metaTags } from "@/constants";
import { ContextProvider } from "@/context";
import { CookieHolder } from "@/sections/cookie-holder";
import "../sentry.client.config";
import "../sentry.edge.config";
import "../sentry.server.config";
import Script from "next/script";

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const RedeclaredAndHacky_Component = Component as any;
  const store = getStore();
  const GA_TRACKING_ID = "GTM-PMM8JJH";

  useEffect(() => {
    if (store && window?.location?.search) {
      const queryObject = window.location.search;
      if (queryObject) {
        store.setQueryString(new URLSearchParams(queryObject));
      }
    }
  }, [store]);

  return (
    <>
      <Script strategy="afterInteractive" id="handTalk">
        {`

      window.onload = function() {
      function handTalk() {
        window.ht = new HT({
          token: '00e5c37be7bb6008e439e0a250ad5762',
          avatar: 'MAYA',
          opacity: 0,
          highContrast: true,
          mobileConfig: {
            align: 'top',
          },
        });
      }
      var l = new Loader();
      l.require([
          "https://plugin.handtalk.me/web/latest/handtalk.min.js"],
        // Callback do lo
        function() {
          handTalk();
        }
      );
    };
       `}
      </Script>
      <Script strategy="afterInteractive" id="hotjar">
        {`
          window.onload = function() {
            function hotjar () {
              (function (h, o, t, j, a, r) {
                h.hj =
                  h.hj ||
                  function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                  };
                h._hjSettings = { hjid: 3617700, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.pages = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
              //
            }
            hotjar();
          };
       `}
      </Script>

      <Head>
        <title>{metaTags.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="O SUV Coupé da Fiat. A evolução da categoria com exclusivo design italiano. Conheça versões, acessórios e muito mais."
        ></meta>
      </Head>
      <StoreProvider value={store}>
        <ContextProvider>
          <Script
            defer
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtm.js?id=${GA_TRACKING_ID}`}
          />
          <Script defer strategy="lazyOnload" id="gtagScript">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `}
          </Script>
          <Script defer strategy="lazyOnload" id="hotjar">
            {`
          (function (h, o, t, j, a, r) {
                h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
                h._hjSettings = { hjid: 3617700, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
       `}
          </Script>
          <Script
            defer
            strategy="lazyOnload"
            id="handtalk"
            src="https://plugin.handtalk.me/web/latest/handtalk.min.js"
            onLoad={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              // @ts-ignore
              const ht = new HT({
                token: "00e5c37be7bb6008e439e0a250ad5762",
                avatar: "MAYA",
                opacity: 0,
                highContrast: true,
                mobileConfig: {
                  align: "top",
                },
              });
            }}
          />

          <div style={{ position: "relative", zIndex: 0 }}>
            <RedeclaredAndHacky_Component {...pageProps} />
          </div>
          <StickyCTA />
          <CookieHolder />
        </ContextProvider>
      </StoreProvider>
    </>
  );
};

MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<{
  pageProps: AppProps["pageProps"];
}> => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
});
