import { PAGE_TITLE, brandLinks } from "@/constants";
import { Context } from "@/context";
import {
  dataMenuButtons,
  dataMenuButtonsMobile,
  dataMenuLabelMobile,
  dataMenuLabels,
} from "@/data/menu.data";
import productDetails, { ProductTab } from "@/data/product-details.data";
import { useMobxStores } from "@/store";
import RampageStore from "@/store/RampageStore";
import DataLayer from "@/utils/DataLayer";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { Conditional } from "..";
import { NewCta } from "../new-cta";
import styles from "./Navigation.module.scss";
import { EComparativeStatus } from "@/context/comparative.context";
import Image from "next/image";
import Logo from "../SvgComponents/Logo";
import MenuScorpion from "../SvgComponents/MenuScorpion";

const Navigation: FC = observer(() => {
  const store: RampageStore = useMobxStores();
  const { state, dispatch, COMPARATIVE_CONTEXT } = useContext(Context);
  const [offset, setOffset] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleSelectedDetailTab = (
    tab: ProductTab | undefined | null
  ): void => {
    if (tab) {
      dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });
    }

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };

  if (COMPARATIVE_CONTEXT.get_comparative_status === EComparativeStatus.OPEN) {
    return null;
  }

  return (
    <>
      {state.layout.menuOpen && (
        <div
          className={styles.gestureDetector}
          onClick={() => {
            dispatch({
              type: "SET_MENU_OPEN",
              payload: { menuOpen: false },
            });
          }}
        />
      )}
      <nav
        id="mainNav"
        className={scssStyles([
          styles.container,
          state.layout.menuOpen ? styles.open : "",
        ])}
        style={{
          top: state.layout.bannerActive
            ? "80px"
            : state.layout.isDesktop
            ? "0"
            : "44px",
          transition: "background-color 0.5s ease",
          background: state.layout.isDesktop
            ? isScrolled || state.layout.comparativeOpen
              ? "#1F1F1F"
              : "#1F1F1F"
            : "#1F1F1F",
        }}
      >
        <div className={styles.scroller} style={{ top: "40px" }}>
          <Conditional notOn="mobile">
            <div className={styles.productLogo}>
              <a
                target="_blank"
                href={brandLinks.mainUrl}
                title={PAGE_TITLE}
                onClick={() => {
                  DataLayer.clickEvent({
                    elementCategory: "link",
                    element: "logo",
                    pageSection: "header",
                    pageSubsection: "menu",
                  });
                }}
              >
                <Logo />
              </a>
            </div>
          </Conditional>

          <Conditional notOn={"desktop"}>
            {dataMenuLabelMobile().map((item, index) => {
              const active = item.id === state.layout.currentlyVisibleNav;
              return (
                  <div className={styles.menuItem} key={`menuItem-${item.id}`}>
                    <a
                      key={item.id}
                      className={scssStyles([
                        styles.menuLinks,
                        active ? styles.active : "",
                      ])}
                      href={item.url}
                      onClick={e => {
                        DataLayer.clickEvent({
                          elementCategory: "link",
                          element: item.label,
                          pageSection: "header",
                          pageSubsection: "menu",
                        });
                        if (item.reference) {
                          dispatch({
                            type: "SCROLL_TO_REFERENCE",
                            payload: { reference: item.reference },
                          });
                          const tabReference = productDetails.find(
                            p => p.slug === item.slug
                          );
                          if (tabReference) {
                            handleSelectedDetailTab(
                              state.layout.selectedDetailTab === tabReference.id
                                ? null
                                : tabReference
                            );
                          }
                          dispatch({
                            type: "SET_MENU_OPEN",
                            payload: { menuOpen: false },
                          });
                        } else {
                          if (index === 0) {
                            window?.scrollTo({ top: 0, behavior: "smooth" });
                          } else {
                            dispatch({
                              type: "SCROLL_TO_REFERENCE",
                              payload: { reference: item },
                            });
                            store.scrollToRef(item);
                          }
                          dispatch({
                            type: "SET_MENU_OPEN",
                            payload: { menuOpen: false },
                          });
                        }

                        e.preventDefault();
                      }}
                    >
                      <span className={scssStyles([styles.linkUnderline])}>
                        {item.label}
                      </span>
                    </a>
                  </div>
              );
            })}

            {!!dataMenuButtonsMobile.length && (
              <div className={styles.buttonsHolder}>
                {dataMenuButtonsMobile.map(item => {
                  const layerEvent = () => {
                    DataLayer.clickEvent({
                      elementCategory: "cta",
                      element: item.label,
                      pageSection: "header",
                      pageSubsection: "menu",
                    });
                  };
                  return (
                    <a
                      style={{ width: "100%", padding: "0 16px" }}
                      target="_blank"
                      href={item.url}
                      key={item.id}
                    >
                      <NewCta
                        label={item.label}
                        className={styles.menuButton}
                        handleClick={() => layerEvent()}
                      />
                    </a>
                  );
                })}
              </div>
            )}
          </Conditional>
          <Conditional notOn={"mobile"}>
            <div className={styles.menuItemArea}>
              {dataMenuLabels()
                .filter(value => value.numberId < 6)
                .map((item, index) => {
                  const reference = state.layout.currentlyVisibleNav;
                  const active = item.id === reference;

                  return (
                      <div
                        className={styles.menuItem}
                        key={`menuItem-${item.id}-${index}`}
                      >
                        <a
                          key={item.id}
                          className={scssStyles([
                            styles.menuLinks,
                            active ? styles.active : "",
                          ])}
                          href={`${item.url}`}
                          onClick={e => {
                            DataLayer.clickEvent({
                              elementCategory: "link",
                              element: item.label,
                              pageSection: "header",
                              pageSubsection: "menu",
                            });
                            if (index === 0) {
                              window?.scrollTo({ top: 0, behavior: "smooth" });
                            } else {
                              dispatch({
                                type: "SCROLL_TO_REFERENCE",
                                payload: { reference: item },
                              });
                            }
                            dispatch({
                              type: "SET_MENU_OPEN",
                              payload: { menuOpen: false },
                            });

                            e.preventDefault();
                          }}
                        >
                          <span>
                            {item.label}
                            {item.isDetails && <MenuScorpion />}
                          </span>
                        </a>
                        {index !== 5 && (
                          <div className={styles.separationLine} />
                        )}
                      </div>
                  );
                })}
            </div>

            {!!dataMenuButtons.length && (
              <div className={styles.buttonsHolder}>
                {dataMenuButtons.map((item, index) => {
                  const layerEvent = () => {
                    DataLayer.clickEvent({
                      elementCategory: "cta",
                      element: item.label,
                      pageSection: "header",
                      pageSubsection: "menu",
                    });
                  };
                  return (
                    <a
                      target="_blank"
                      href={item.url}
                      key={`menu-cta-${item.id}`}
                    >
                      <NewCta
                        key={`menu-cta-${item.id}`}
                        handleClick={layerEvent}
                        label={item.label}
                        className={styles.menuButton}
                        width="184px"
                        secundary={index !== 0 ? true : false}
                        icon={
                          <svg
                            id="seta"
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.706"
                            height="11.944"
                            viewBox="0 0 7.706 11.944"
                          >
                            <rect
                              id="Retângulo_2683"
                              data-name="Retângulo 2683"
                              width="2.237"
                              height="8.451"
                              transform="matrix(-0.682, 0.731, -0.731, -0.682, 7.706, 5.763)"
                            />
                            <rect
                              id="Retângulo_2684"
                              data-name="Retângulo 2684"
                              width="8.451"
                              height="2.237"
                              transform="matrix(-0.682, 0.731, -0.731, -0.682, 7.399, 5.763)"
                            />
                          </svg>
                        }
                      />
                    </a>
                  );
                })}
              </div>
            )}
          </Conditional>
        </div>
      </nav>
    </>
  );
});

export default Navigation;
