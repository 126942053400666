import { FC } from 'react';

interface VimeoEmbedProps {
  title: string;
  id?: string;
  className?: string;
  sublink?: string;
  youtubeVideoId?: string;
}

const VimeoEmbed: FC<VimeoEmbedProps> = ({
  title,
  id,
  className = '',
  sublink = '5f2b4628e5F',
  youtubeVideoId,
}) => {
  return (
    <>
      <iframe
          src={`https://player.vimeo.com/video/${id}?h=${sublink}&loop=1&autopause=1`}
          title={title}
          className={className}
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop"
          loading="lazy"
      />
    </>
  );
};

export default VimeoEmbed;
