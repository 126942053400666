import {
  layoutInitialState,
  layoutReducer,
  LayoutType,
} from '@/reducers/layoutReducer';
import { reducerActionType } from '@/types/reducerActionType';
import React, { createContext, useReducer, useState } from 'react';
import {
  AUTONOMY_INITIAL_VALUE,
  IAutonomyContextType,
} from './autonomy.context';
import {
  IVersionsContextType,
  VERSION_INITIAL_VALUE,
} from './versions.context';
import formatString from '@/utils/formatString';
import {
  handleReturnDefaultColorByVersion,
  IVersionsData,
  VERSIONS_DATA,
} from '@/data/versions.data';
import {
  COMPARATIVE_INITIAL_VALUE,
  EComparativeStatus,
  IComparativeContextType,
} from './comparative.context';

interface initialStateType {
  layout: LayoutType;
}

interface ContextType
  extends IAutonomyContextType,
    IVersionsContextType,
    IComparativeContextType {
  state: initialStateType;
  dispatch: React.Dispatch<any>;
}

const initialState: initialStateType = {
  layout: layoutInitialState,
};

export const Context = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
  ...AUTONOMY_INITIAL_VALUE,
  ...VERSION_INITIAL_VALUE,
  ...COMPARATIVE_INITIAL_VALUE,
});

const mainReducer = (state: initialStateType, action: reducerActionType) => ({
  layout: layoutReducer(state.layout, action),
});

export const ContextProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const [origin, setOrigin] = useState<string>('');
  const [destiny, setDestiny] = useState<string>('');
  const [distance, setDistance] = useState(0);
  const [consume, setConsume] = useState(0);
  const [constancy, setConstancy] = useState(0);
  const [velocity, setVelocity] = useState(50);
  const [economy, setEconomy] = useState(true);
  const [intenseTraffic, setIntenseTraffic] = useState(false);
  const [doubleDestiny, setDoubleDestiny] = useState(false);
  const [chargerPortraitTime, setChargerPortaitTime] = useState(0);
  const [chargerFastTime, setChargerFastTime] = useState(0);
  const [chargerUltraFastTime, setChargerUltraFastTime] = useState(0);
  const [userConsumoDisplay, setUserConsumoDisplay] = useState(100);
  const [autonomyFullDisplay, setAutonomyFullDisplay] = useState(0);
  const [userDistanceDisplay, setUserDistanceDisplay] = useState('');
  const [relativeConsume, setRelativeConsume] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [get_comparative_status, _set_comparative_status] =
    useState<EComparativeStatus>(EComparativeStatus.CLOSE);

  const set_comparative_status = (status: EComparativeStatus) => {
    _set_comparative_status(status);

    if (status === EComparativeStatus.OPEN) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };
  const [get_selected_version_index, set_selected_version_index] =
    useState<number>(0);
  const [get_selected_version_name, set_selected_version_name] =
    useState<string>(formatString(VERSIONS_DATA[0].slug));
  const [get_selected_color, set_selected_color] = useState<string>(
    handleReturnDefaultColorByVersion()
  );
  const [get_selected_color_name, set_selected_color_name] =
    useState<string>('preto-vulcano');
  const [get_version_src, set_version_src] = useState<string>(
    VERSIONS_DATA[0].path
  );
  const [get_version, set_version] = useState<IVersionsData>(VERSIONS_DATA[0]);

  const VERSION_CONTEXT = {
    get_selected_version_name,
    set_selected_version_name,
    get_selected_version_index,
    set_selected_version_index,
    get_selected_color,
    set_selected_color,
    get_selected_color_name,
    set_selected_color_name,
    get_version_src,
    set_version_src,
    get_version,
    set_version,
  };

  const AUTONOMY_CONTEXT = {
    distance,
    setDistance,

    consume,
    setConsume,

    constancy,
    setConstancy,

    origin,
    setOrigin,

    destiny,
    setDestiny,

    velocity,
    setVelocity,

    economy,
    setEconomy,

    intenseTraffic,
    setIntenseTraffic,

    doubleDestiny,
    setDoubleDestiny,

    chargerPortraitTime,
    setChargerPortaitTime,
    chargerFastTime,
    setChargerFastTime,
    chargerUltraFastTime,
    setChargerUltraFastTime,
    userConsumoDisplay,
    setUserConsumoDisplay,
    autonomyFullDisplay,
    setAutonomyFullDisplay,
    userDistanceDisplay,
    setUserDistanceDisplay,
    relativeConsume,
    setRelativeConsume,
    modalIsOpen,
    setModalIsOpen,
  };

  const COMPARATIVE_CONTEXT = {
    get_comparative_status,
    set_comparative_status,
  };

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        AUTONOMY_CONTEXT,
        VERSION_CONTEXT,
        COMPARATIVE_CONTEXT,
      }}
    >
      {children}
    </Context.Provider>
  );
};
