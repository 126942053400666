import { FC } from "react";

const Pointer: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138.803"
      height="18.396"
      viewBox="0 0 138.803 18.396"
    >
      <g id="Toque" transform="translate(-274.789 -336.381)">
        <text
          id="Toque_e_veja_as_novidades"
          data-name="Toque e veja as novidades"
          transform="translate(299.593 350)"
          font-size="10"
          font-family="SegoeUI-Bold, Segoe UI"
          font-weight="700"
          letter-spacing="-0.034em"
        >
          <tspan x="0" y="0">
            Toque e veja as novidades
          </tspan>
        </text>
        <g id="Icon" transform="translate(204.789 336.381)">
          <path
            id="Caminho_4603"
            data-name="Caminho 4603"
            d="M79.2,0A9.2,9.2,0,1,1,70,9.2,9.2,9.2,0,0,1,79.2,0m0,.613A8.585,8.585,0,1,0,87.783,9.2,8.595,8.595,0,0,0,79.2.613Z"
          />
          <path
            id="Caminho_4604"
            data-name="Caminho 4604"
            d="M124.335,46.668a7.665,7.665,0,1,1-7.665,7.665,7.665,7.665,0,0,1,7.665-7.665m0,.613a7.052,7.052,0,1,0,7.052,7.052A7.06,7.06,0,0,0,124.335,47.281Z"
            transform="translate(-45.137 -45.135)"
          />
          <path
            id="Caminho_4605"
            data-name="Caminho 4605"
            d="M169.462,93.332a6.132,6.132,0,1,1-6.132,6.132,6.132,6.132,0,0,1,6.132-6.132m0,.613a5.519,5.519,0,1,0,5.519,5.519A5.525,5.525,0,0,0,169.462,93.945Z"
            transform="translate(-90.264 -90.266)"
          />
          <path
            id="Caminho_4606"
            data-name="Caminho 4606"
            d="M214.6,140a4.6,4.6,0,1,1-4.6,4.6,4.6,4.6,0,0,1,4.6-4.6m0,.613a3.986,3.986,0,1,0,3.986,3.986A3.99,3.99,0,0,0,214.6,140.613Z"
            transform="translate(-135.401 -135.401)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Pointer;
