import { SetStateAction } from "react";

interface IAutonomyContext {
  origin: string;
  setOrigin: React.Dispatch<SetStateAction<string>>;

  destiny: string;
  setDestiny: React.Dispatch<SetStateAction<string>>;

  distance: number;
  setDistance: React.Dispatch<SetStateAction<number>>;

  consume: number;
  setConsume: React.Dispatch<SetStateAction<number>>;

  constancy: number;
  setConstancy: React.Dispatch<SetStateAction<number>>;

  velocity: number;
  setVelocity: React.Dispatch<SetStateAction<number>>;

  economy: boolean;
  setEconomy: React.Dispatch<SetStateAction<boolean>>;

  intenseTraffic: boolean;
  setIntenseTraffic: React.Dispatch<SetStateAction<boolean>>;

  doubleDestiny: boolean;
  setDoubleDestiny: React.Dispatch<SetStateAction<boolean>>;

  chargerPortraitTime: number;
  setChargerPortaitTime: React.Dispatch<SetStateAction<number>>;

  chargerFastTime: number;
  setChargerFastTime: React.Dispatch<SetStateAction<number>>;

  chargerUltraFastTime: number;
  setChargerUltraFastTime: React.Dispatch<SetStateAction<number>>;

  userConsumoDisplay: number;
  setUserConsumoDisplay: React.Dispatch<SetStateAction<number>>;

  autonomyFullDisplay: number;
  setAutonomyFullDisplay: React.Dispatch<SetStateAction<number>>;

  userDistanceDisplay: string;
  setUserDistanceDisplay: React.Dispatch<SetStateAction<string>>;

  relativeConsume: number;
  setRelativeConsume: React.Dispatch<SetStateAction<number>>;

  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<SetStateAction<boolean>>;
}

export interface IAutonomyContextType {
  AUTONOMY_CONTEXT: IAutonomyContext;
}

export const AUTONOMY_INITIAL_VALUE: IAutonomyContextType = {
  AUTONOMY_CONTEXT: {
    distance: 0,
    setDistance: () => null,

    consume: 0,
    setConsume: () => null,

    constancy: 0,
    setConstancy: () => null,

    origin: "",
    setOrigin: () => null,

    destiny: "",
    setDestiny: () => null,

    velocity: 50,
    setVelocity: () => null,

    economy: true,
    setEconomy: () => null,

    intenseTraffic: false,
    setIntenseTraffic: () => null,

    doubleDestiny: false,
    setDoubleDestiny: () => null,

    chargerPortraitTime: 0,
    setChargerPortaitTime: () => null,
    chargerFastTime: 0,
    setChargerFastTime: () => null,
    chargerUltraFastTime: 0,
    setChargerUltraFastTime: () => null,
    userConsumoDisplay: 100,
    setUserConsumoDisplay: () => null,
    autonomyFullDisplay: 0,
    setAutonomyFullDisplay: () => null,
    userDistanceDisplay: "",
    setUserDistanceDisplay: () => null,
    relativeConsume: 0,
    setRelativeConsume: () => null,
    modalIsOpen: false,
    setModalIsOpen: () => null,
  },
};
