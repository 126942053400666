import { Context } from "@/context";
import {
  links as brandLinks,
  COMPRE_TITLE,
  MONTE_TITLE,
} from "@/data/menu.data";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import styles from "./StickyCTA.module.scss";
import DataLayer from "@/utils/DataLayer";
import { NewCta } from "../new-cta";

const layerEvent = (element: string) => {
  DataLayer.clickEvent({
    elementCategory: "cta",
    element: element,
    pageSection: "versao",
    pageSubsection: `float`,
  });
};

const StickyCTA: FC = observer(() => {
  const [isShown, setIsShown] = useState(false);
  const [delay, setDelay] = useState(false);
  const { state } = useContext(Context);

  const hide = () => {
    if (isShown) {
      setIsShown(false);
    }
  };

  const show = () => {
    setTimeout(() => {
      setIsShown(true);
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setDelay(false);
    }, 500);
  }, []);

  useIdleTimer({
    timeout: 60 * 3,
    onActive: () => hide(),
    onAction: () => show(),
    debounce: 2000,
  });

  if (state.layout.isDesktop || delay) {
    return null;
  }
  return (
    <>
      {state.layout.galleryExpanded || state.layout.menuOpen || state.layout.currentlyVisibleNav === 'datamenu-2' || state.layout.currentlyVisibleNav === 'datamenu-0' || state.layout.currentlyVisibleNav === 'footer'? (
        <></>
      ) : (
        <div
          className={scssStyles([
            styles.container,
            isShown ? styles.active : "",
          ])}
        >
          <a target="_blank" href={brandLinks.monte}>
            <NewCta
              width={"100%"}
              label={MONTE_TITLE}
              handleClick={() => layerEvent(MONTE_TITLE)}
            />
          </a>
          <a target="_blank" href={brandLinks.monte}>
            <NewCta
              width={"100%"}
              label={COMPRE_TITLE}
              handleClick={() => layerEvent(COMPRE_TITLE)}
              secundary
            />
          </a>
        </div>
      )}
    </>
  );
});

export default StickyCTA;
