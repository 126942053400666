import { GALLERY_PREFIX } from '@/utils/imagePrefixes';


interface VideoProps {
  desktop: string;
  mobile: string;
}
export interface GalleryAsset {
  id: string;
  title: string;
  asset: string;
  thumb: string;
  video?: VideoProps;
  alt: string;
}

export const gallery: GalleryAsset[] = [
  {
    id: 'ga01',
    title: 'Design Superesportivo',
    asset: `${GALLERY_PREFIX}/fiat-fastback-limited-branco@3x.webp`,
    thumb: `${GALLERY_PREFIX}/fiat-fastback-limited-branco-thumb.webp`,
    alt: 'Fastback Abarth vermelho em visão lateral dianteira.',
  },
  {
    id: 'ga02',
    title: 'Tecnologia',
    asset: `${GALLERY_PREFIX}/volante-multifuncional@3x.webp`,
    thumb: `${GALLERY_PREFIX}/volante-multifuncional-thumb.webp`,
    alt: 'Volante, painel de controle e central multimídia sob ponto de vista do motorista.',
  },

  {
    id: 'ga03',
    title: 'Automatização de Faróis',
    asset: `${GALLERY_PREFIX}/grade-frontal@3x.webp`,
    thumb: `${GALLERY_PREFIX}/grade-frontal-thumb.webp`,
    alt: 'Frente do Fastback Abarth, com destaque para o farol de LED, detalhes de design esportivo e grade com o emblema Abarth',
  },

  {
    id: 'ga04',
    title: 'Badge Abarth',
    asset: `${GALLERY_PREFIX}/porta-malas@3x.webp`,
    thumb: `${GALLERY_PREFIX}/porta-malas-thumb.webp`,
    alt: 'Emblema Abarth de escorpião',
  },

  {
    id: 'ga05',
    title: 'Feito Sob Medida',
    asset: `${GALLERY_PREFIX}/roda-liga-leve@3x.webp`,
    thumb: `${GALLERY_PREFIX}/roda-liga-leve-thumb.webp`,
    alt: 'Bancos revestidos em couro com detalhes na costura, emblema de escorpião e apoio de braço.',
  },

  {
    id: 'ga06',
    title: 'Performance Eficiente',
    asset: `${GALLERY_PREFIX}/bancos-couro@3x.webp`,
    thumb: `${GALLERY_PREFIX}/bancos-couro-thumb.webp`,
    alt: 'Fastback Abarth vermelho em visão lateral traseira.',
  },
];

export const galleryThumbs = gallery.map((g) => ({
  ...g,
  thumb: `${g.thumb}`,
}));
