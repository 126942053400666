import { MenuCTAs, MenuLabels } from '@/models';
import productDetails, { ProductTab } from './product-details.data';

export const PRODUCT_DETAILS_PREFIX = 'tudo-sobre-fastback';
export const PRODUCT_DETAILS_REF: MenuLabels = {
  numberId: 5,
  id: 'tudo-sobre-fastback',
  label: 'Tudo sobre o Fastback',
  slug: PRODUCT_DETAILS_PREFIX,
  url: `/${PRODUCT_DETAILS_PREFIX}`,
  nestedPrefix: PRODUCT_DETAILS_PREFIX,
};

const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
  if (!pDetails) {
    return [];
  }
  return pDetails.reduce((prev, item, index) => {
    return [
      ...prev,
      {
        id: `data-menu-details-${index}`,
        label: item.title,
        slug: `${item.slug}`,
        url: `${item.slug}`,
        nestedPrefix: PRODUCT_DETAILS_PREFIX,
        reference: PRODUCT_DETAILS_REF,
        tabId: item.id,
        isDetails: true,
      } as MenuLabels,
    ];
  }, [] as MenuLabels[]);
};

export const links = {
  compre: "https://www.fiat.com.br/compre/fiat-fastback.html",
  negociar: "https://abarth.fiat.com.br/monte.html",
  ofertas: "https://ofertas.fiat.com.br/?modelCode=376",
  reserve: "https://abarth.fiat.com.br/monte.html",
  financiamento:
    "https://fastback.fiat.com.br/monte.html?openFundingPlans=true#simule-o-financiamento",
  monte: "https://fastback.fiat.com.br/monte.html",
  concessionarias: "https://www.fiat.com.br/concessionarias.html",
  whatsapp: "",
  abarth: "https://fastback.fiat.com.br/abarth/",
};

export const COMPRE_TITLE = 'Compre o seu';
export const MONTE_TITLE = 'Monte o seu';
export const FINANCIAMENTO_TITLE = 'Simular financiamento';
export const CONCESSIONARIAS_TITLE = 'Concessionárias';
export const OFERTAS_TITLE = 'Ver ofertas';
export const RESERVE_TITLE = 'Reserve o seu';
export const NEGOCIART_TITLE = 'Agendar test drive';

export const dataMenuLabelMobile: () => MenuLabels[] = () => [
  {
  numberId: 0,
    id: 'datamenu-0',
    label: 'Novo Fiat Fastback',
    url: '/',
    slug: '',
  },
  {
    numberId: 1,
    id: 'datamenu-1',
    label: 'Fastback Abarth',
    url: '/',
    slug: 'powered-by-abarth',
  },
  {
    numberId: 2,
    id: 'datamenu-2',
    label: 'Versões',
    url: '/',
    slug: 'versoes',
  },
  {
    numberId: 3,
    id: 'datamenu-3',
    label: 'Fotos',
    url: '/',
    slug: 'galeria',
  },
  {
    numberId: 4,
    id: 'datamenu-4',
    label: 'Connect////Me',
    url: '/',
    slug: 'connectme',
  },
  ...getProductDetails(productDetails),
];

export const dataMenuLabels: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: "datamenu-0",
    label: "Fiat Fastback",
    url: "/",
    slug: "",
  },
  {
    numberId: 1,
    id: "datamenu-1",
    label: "Fastback Abarth",
    url: "/",
    slug: "powered-by-abarth",
    isDetails: true,
  },
  {
    numberId: 2,
    id: "datamenu-2",
    label: "Versões",
    url: "/versoes",
    slug: "versoes",
  },
  {
    numberId: 3,
    id: "datamenu-3",
    label: "Fotos",
    url: "/galeria",
    slug: "galeria",
  },
  {
    numberId: 4,
    id: "datamenu-4",
    label: "Connect////Me",
    url: "/",
    slug: "connectme",
  },

  {
    numberId: 5,
    id: "tudo-sobre-fastback",
    label: "Tudo sobre",
    url: "/tudo-sobre-fastback",
    slug: "tudo-sobre-fastback",
  },
  {
    numberId: 6,
    id: "datamenu-6",
    label: "Comparativo",
    url: "/versoes/comparativo",
    slug: "versoes/comparativo",
  },
  ...getProductDetails(productDetails),
  {
    numberId: getProductDetails(productDetails).length + 1,
    id: "datamenu-11",
    label: "destaques",
    url: "/destaques",
    slug: "destaques",
  },
];

export const dataMenuButtonsMobile: MenuCTAs[] = [
  {
    id: 'datamenubutton-0',
    label: MONTE_TITLE,
    url: links.monte,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-1',
    label: COMPRE_TITLE,
    url: links.compre,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-2',
    label: FINANCIAMENTO_TITLE,
    url: links.financiamento,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-3',
    label: 'Agendar test drive',
    url: links.negociar,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-4',
    label: CONCESSIONARIAS_TITLE,
    url: links.concessionarias,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
];

export const dataFooterButtonsMobile: MenuCTAs[] = [
  {
    id: 'datamenubutton-0',
    label: MONTE_TITLE,
    url: links.monte,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-2',
    label: COMPRE_TITLE,
    url: links.compre,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-1',
    label: FINANCIAMENTO_TITLE,
    url: links.financiamento,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-4',
    label: CONCESSIONARIAS_TITLE,
    url: links.concessionarias,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-5',
    label: OFERTAS_TITLE,
    url: links.ofertas,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
];

export const dataMenuButtons: MenuCTAs[] = [
  {
    id: 'datamenubutton-1',
    url: links.compre,
    label: COMPRE_TITLE,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
  {
    id: 'datamenubutton-2',
    label: MONTE_TITLE,
    url: links.monte,
    backgroundColor: '#1FC1D5',
    color: '#fff',
  },
];
