export { default as ChevronBold } from './ChevronBold';
export { default as Chevron } from './Chevron';
export { default as ChevronRight } from './ChevronRight';
export { default as Close } from './Close';
export { default as Arrow } from './Arrow';
export { default as Magnifying } from './Magnifying';
export { default as LoadingFlag } from './LoadingFlag';
export { default as Pointer } from './Pointer';
export { default as Detail } from './Detail';
export { default as Plus } from './Plus';
export { default as LogoConsumerType } from './LogoConsumerType';
export { default as ScorpionSvg } from './Scorpion';
