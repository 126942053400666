import { MetaType } from '@models/index';
import {
  ProjectBrand,
  ProjectInfoInterface,
  ProjectType,
} from '@models/ProjectInfo';
import { PREFIX } from '@utils/imagePrefixes';

export const BRAND_NAME = 'FIAT';
export const PAGE_TITLE = `${BRAND_NAME} Fastback`;

export const project: ProjectInfoInterface = {
  year: '2025',
  version: '1.5',
  debug: true,
  product: 'fastback-abarth',
  brand: ProjectBrand.FIAT,
  type: ProjectType.LANDING_PAGE,
  url: 'https://fastback.fiat.com.br',
};

export const metaTags: MetaType = {
  description:
    'Envenenado de fábrica, o Fastback Abarth possui o melhor motor da categoria, conectividade embarcada e design superesportivo. Saiba mais sobre essa máquina aqui.',
  title: 'Fastback Abarth | FIAT',
  brandName: 'Fastback Abarth',
  productName: 'Fastback Abarth',
  heading:
    'Fastback Abarth | FIAT',

  facebook: {
    image: '/images/OG/og-twitter-image@3x.webp',
    imageHeight: '630',
    imageWidth: '1200',
    type: 'website',
  },
  twitter: {
    description:
      'Envenenado de fábrica, o Fastback Abarth possui o melhor motor da categoria, conectividade embarcada e design superesportivo. Saiba mais sobre essa máquina aqui.',
    card: 'summary',
    site: '@fiatbr',
    image: '/images/OG/og-twitter-image@3x.webp',
  },
  url: 'https://fastback.fiat.com.br/abarth',
  image: '/images/OG/og-image.jpg',
  imageAlt: 'Fastback Abarth – Liberte o veneno que vive em você.',
};
export const STORAGE_PREFIX = '@fiat-pulse-abarth/';

export const brandLinks = {
  mainUrl: 'https://abarth.fiat.com.br',
  privacyPolicy: 'https://www.fiat.com.br/politica-de-privacidade.html',
  twitter: 'https://twitter.com/fiatbr',
  facebook: 'https://www.facebook.com/fiatbr',
  instagram: 'https://www.instagram.com/fiatbr/',
  youtube: 'https://www.youtube.com/@fiatbr/featured',
};

export * as menu from '../data/menu.data';
