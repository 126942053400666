import { ui } from '@/assets';
import { PAGE_TITLE, brandLinks } from '@/constants';
import { Context } from '@/context';
import useDocumentScrollThrottled from '@/hooks/useDocumentScrollThrottled';
import DataLayer from '@/utils/DataLayer';
import scssStyles from '@/utils/scssStyles';
import { HamburguerButton } from '@components/index';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useState } from 'react';
import styles from './Header.module.scss';
import { HeaderLogoMobileIcon } from '../SvgComponents/header-logo-mobile.icon';
import Image from 'next/image';

const Header: FC = observer(() => {
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const { dispatch, state } = useContext(Context);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 400;

  let timeout: NodeJS.Timeout;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div
      className={scssStyles([
        styles.container,
        shouldHideHeader ? styles.hide : "",
      ])}
      style={{ top: state.layout.bannerActive ? "80px" : 0 }}
    >
      <div className={scssStyles([styles.mobile])}>
        <HamburguerButton
          title="Abrir menu"
          handleClick={() => {
            const open = !state.layout.menuOpen;
            DataLayer.clickEvent({
              element: `menu-${open ? "abrir" : "fechar"}`,
              elementCategory: "botao",
              pageSection: "header",
              pageSubsection: "menu",
            });
            dispatch({
              type: "SET_MENU_OPEN",
              payload: { menuOpen: open },
            });
          }}
        >
          <img src={ui.menuClosed} alt="Menu" />
        </HamburguerButton>
        <div className={styles.titleWrapper}>
          <a
            target="_blank"
            href={brandLinks.mainUrl}
            title={PAGE_TITLE}
            className={styles.title}
            onClick={() => {
              DataLayer.clickEvent({
                elementCategory: "link",
                element: "logo",
                pageSection: "header",
                pageSubsection: "menu",
              });
            }}
          >
            <HeaderLogoMobileIcon />
          </a>
        </div>
      </div>
    </div>
  );
});

export default Header;
