export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  desktop: 1024,
  xl: 1200,
  maxsize: 1920,
};

export const COLORS = {
  PRIMARY: '#E72120',
  BASIC: {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
  },
  VERSIONS: {
    CIAN: '#92D0DB',
    BORDO: '#A31345',
    MOSTARD: '#FFBA27',
    MARINE: '#222652',
    RED: '#FF1431',
    PURPLE: '#3A369E',
    DEFAULT: '#EDEDE3',
    ABARTH: '#E72120',
  },
};
