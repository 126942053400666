import { FC } from "react";

const Arrow: FC<{ color?: string; title?: string }> = ({
  color = "#FFFFFF",
  title = "Próximo",
}) => {
  return (
    <svg
      id="Grupo_4295"
      data-name="Grupo 4295"
      xmlns="http://www.w3.org/2000/svg"
      width="11.217"
      height="17.392"
      viewBox="0 0 11.217 17.392"
    >
      <rect
        id="Retângulo_2683"
        data-name="Retângulo 2683"
        width="3.257"
        height="12.305"
        transform="translate(11.217 8.399) rotate(133.041)"
      />
      <rect
        id="Retângulo_2684"
        data-name="Retângulo 2684"
        width="12.305"
        height="3.257"
        transform="translate(11.217 8.398) rotate(133.041)"
      />
    </svg>
  );
};

export default Arrow;
